import { createQueryHook, gql } from '../graphqlHelpers';

const query = gql`
  query ReconSettings($listId: ID!) {
    list(id: $listId) {
      id
      name
      reconciliations
      reconEndDate
      currentUserIsOwner
      tasks {
        id
        parentId
        name
        currency
        listNumber
        reconciliations
        tags { 
          id 
          tag
        }
        classifications{
          id
          name
        }
        section {
          id
          name
        }
        list {
          id
          # ease of use 
          reconEndDate
        }
        primaryLabel
        primarySourceType
        primarySource {
          id
          value
          fileVersion {
            id
            fileName
            fileLocation
            taskId
          }
          integrationInfo {
            id
            companyId
            locationId
            ledgerId
            transactionType 
            period 
            slStartDate
            slEndDate

            ###########################
            #intacct stuff
            ###########################
            inEntities {
              label
              value 
            }
            inDepartments {
              label
              value 
            }
            inUnderliers {
              label
              value 
            }
            inAccounts {
              label
              value 
            }
            inServices {
              label
              value 
            }
            inCustomers {
              label
              value 
            }
            inVendors {
              label
              value 
            }
            inAccountingMethod {
              label
              value 
            }
            inStartDate
            inEndDate
            startDateLocked
            endDateLocked
            invert
            includeReportingBook

            ###########################
            #qbo stuff
            ###########################
            qboLocations {
              label
              value 
            }
            qboAccounts {
              label
              value 
            }
            qboNames {
              label
              value 
            }
            qboClasses {
              label
              value 
            }
            qboTypes {
              label
              value 
            }
            qboAccountingMethod {
              label
              value 
            }
            qboStartDate
            qboEndDate
            qboIntegration {
              label
              value
            }

            ######################
            #ns stuff
            ######################
            nsAccounts {
              label
              value
            }
            nsSubsidiaries{
              label
              value
            }
            nsLocations {
              label
              value
            }
            nsDepartments {
              label
              value
            }
            nsClasses {
              label
              value
            }
            nsItems {
              label
              value
            }
            nsEntities {
              label
              value
            }
            nsBooks {
              label
              value
            }
            nsStartDate
            nsEndDate
            currency
          }
        }
        secondaryLabel
        secondarySourceType
        secondarySource {
          id
          value
          fileVersion {
            taskId
            id
            fileName
            fileLocation
          }
          integrationInfo {
            id
            companyId
            locationId
            ledgerId
            transactionType 
            period 

            ###########################
            #intacct stuff
            ###########################
            inEntities {
              label
              value 
            }
            inDepartments {
              label
              value 
            }
            inUnderliers {
              label
              value 
            }
            inAccounts {
              label
              value 
            }
            inServices {
              label
              value 
            }
            inCustomers {
              label
              value 
            }
            inVendors {
              label
              value 
            }
            inAccountingMethod {
              label
              value 
            }
            inStartDate
            inEndDate
            startDateLocked
            endDateLocked
            invert
            includeReportingBook

            ###########################
            #qbo stuff
            ###########################
            qboAccounts {
              label
              value 
            }
            qboNames {
              label
              value 
            }
            qboClasses {
              label
              value 
            }
            qboLocations {
              label
              value 
            }
            qboTypes {
              label
              value 
            }
            qboAccountingMethod {
              label
              value 
            }
            qboStartDate
            qboEndDate
            qboIntegration {
              label
              value
            }

            ######################
            #ns stuff
            ######################
            nsAccounts {
              label
              value
            }
            nsSubsidiaries{
              label
              value
            }
            nsLocations {
              label
              value
            }
            nsDepartments {
              label
              value
            }
            nsClasses {
              label
              value
            }
            nsItems {
              label
              value
            }
            nsEntities {
              label
              value
            }
            nsBooks {
              label
              value
            }
            nsStartDate
            nsEndDate
            currency
            slStartDate
            slEndDate
          }
        }
      }
    }
  }
`

export const useReconSettings = createQueryHook(query);
