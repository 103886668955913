import React, {Component, useState, useEffect, useRef} from 'react';
import clsx from 'clsx';
import idx from 'idx';
import {Theme, makeStyles, createStyles} from '@material-ui/core/styles';

import * as cs from '../../constants/theme';
import CreatableSelect from 'react-select/creatable';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      position: 'relative',
      // zIndex: 2,
      // width: 344,
      width: 160,
      display: 'flex',
      '&:hover': {
        cursor: 'pointer',
      },
    },
    selector: {
      display: 'block',
      // width: 344,
      width: 160,
      marginTop: '0px',
      color: '#606060',
      fontFamily: cs.FONT.family,
      fontWeight: cs.FONT.weight.regular,
      fontSize: cs.FONT.size.xs,
    },
  })
);

interface CreatableAutoSelectProps {
  options: any;
  onChange?: any;
  value?: any;
  placeholder?: string;
  LeftIcon?: any;
  RightIcon?: any;
  selectorClass?: string;
  containerClass?: string;
  fullWidth?: boolean;
  optionsStyle?: any;
  isDisabled?: boolean;
}

export default function CreatableAutoSelect({
  options,
  onChange,
  value,
  placeholder,
  LeftIcon,
  RightIcon,
  selectorClass,
  containerClass,
  fullWidth,
  optionsStyle = {},
  isDisabled
}: CreatableAutoSelectProps) {
  const classes = useStyles({});
  const customSelectStyle = {
    control: () => ({
      alignItems: 'center',
      fontFamily: 'Montserrat',
      border: 'none',
      display: 'flex',
      flexWrap: 'wrap',
      justifyContent: 'space-between',
      minHeight: 38,
      position: 'relative',
      transition: 'all 100ms',
      boxSizing: 'border-box',
      cursor: 'pointer',
    }),
    indicatorsContainer: () => ({display: 'none'}),
    dropdownIndicator: () => ({display: 'none', color: 'transparent'}),
    valueContainer: () => ({
      padding: 0,
    }),
    placeholder: () => ({
      color: '#AFAFAF',
      // fontSize: '15px',
      fontSize: '12px',
      fontWeight: 500,
      position: 'absolute',
      fontFamily: 'Montserrat',
      top: '50%',
      transform: 'translateY(-50%)',
    }),
    option: (provided: any, state: any) => ({
      ...provided,
      background: state.isFocused ? '#DEEBFF' : '#FFFF',
      color: '#606060',
    }),
    input: () => ({
      color: 'dimgrey',
      fontSize: '12px',
      fontWeight: 500,
    }),
    menu: (provided: any) => ({
      ...provided,
      boxShadow: 'none',
      top: '20px',
      ...optionsStyle
    }),
    menuList: (provided: any) => ({
      ...provided,
      border: '1px solid #D8D8D8',
      borderRadius: 2,
      paddingTop: 0,
      paddingBottom: 0,
      width: fullWidth ? '100%' : '95%',
    }),
    singleValue: () => ({
      color: '#606060',
      fontSize: '12px',
      fontWeight: 500,
      marginRight: '2px',
      maxWidth: 'calc(100% - 8px)',
      overflow: 'hidden',
      position: 'absolute',
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      top: '50%',
      webkitTransform: 'translateY(-50%)',
      msTransform: 'translateY(-50%)',
      transform: 'translateY(-50%)',
      boxSizing: 'border-box',
    }),
  };

  const [inputValue, setInputValue] = useState<string | undefined>(undefined);
  const [isFocused, setIsFocused] = useState<boolean>(false);
  const componentRef: any = useRef(null);

  /** */
  function handleInputChange(e: any) {
    setInputValue(e);
  }

  function handleMenuOpen() {
    setInputValue('');
  }

  function onClickIcon() {
    if (isFocused) {
      componentRef.current.blur();
      setIsFocused(false);
    } else {
      componentRef.current.focus();
      setIsFocused(true);
    }
  }

  return (
    <div className={clsx(classes.root, containerClass)}>
      {LeftIcon && <LeftIcon onClick={onClickIcon} />}
      <CreatableSelect
        isFocused={isFocused}
        ref={componentRef}
        openMenuOnFocus={true}
        className={clsx(classes.selector, selectorClass)}
        inputValue={inputValue}
        isClearable
        // @ts-ignore
        onChange={onChange}
        onInputChange={handleInputChange}
        onMenuOpen={handleMenuOpen}
        options={options}
        placeholder={placeholder}
        styles={customSelectStyle}
        value={value.label && value}
        isDisabled={isDisabled}
      />
      {RightIcon && <RightIcon onClick={onClickIcon} />}
    </div>
  );
}
