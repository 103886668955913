// linted -- mpf 11/20
import React from 'react';
import { render } from 'react-dom';

import { InMemoryCache, HttpLink, from, ApolloProvider, ApolloClient, makeVar, NormalizedCacheObject } from '@apollo/client';
import { RetryLink } from '@apollo/client/link/retry';
import { onError } from "@apollo/client/link/error";

import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider } from '@material-ui/styles';

import theme from './config/theme';
import App from './containers/app';
import { Provider } from './store';
import { offsetLimitPagination } from '@apollo/client/utilities';
import { DETAILED_TASK_INFO } from './graphql/fragments/detailedTaskInfo';
import Auth0ProviderWithHistory from './modules/auth/Auth0ProviderWithHistory';
import { buildDynamicSubTaskFragment, buildDynamicTaskFragment } from './graphql/queries/FragmentTaskPageUserTasks';
import { updateSubTaskInDeep, updateStatusForAllSubTasks } from './helpers/helpers';

const getCSRFToken = () => {
  const el = document.querySelector('meta[name="csrf-token"]');
  return (el && el.getAttribute('content')) || '';
}

export enum DueDateGroupingEnum {
  RECENTLY_UPDATED = 'recently updated',
  LATE = 'late',
  TODAY = 'today',
  TOMORROW = 'tomorrow',
  THIS_WEEK = 'this week',
  LATER = 'later',
}

document.addEventListener('DOMContentLoaded', () => {
  const abortController = new AbortController();

  const link = from([
    new RetryLink({
      attempts: {
        max: 5,
        retryIf: (error, _operation) => {
          if (_operation.operationName === 'CurrentUser' && error.statusCode === 401) {
            return false;
          }
          return true;
        }
      }
    }),
    new HttpLink({
      credentials: 'same-origin',
      headers: {
        'X-CSRF-Token': getCSRFToken()
      },
      fetchOptions: {
        signal: abortController.signal
      }
    })
  ]);

  const invalidTokenLink = onError(({networkError }) => {
    if (networkError) {
      if (`${networkError}`.includes('Received status code 422')) {
        alert('Your session has expired. Please reauthenticate to continue using the application.');
        window.location.href = '/signin';
      }
    }
  });

  const cache = new InMemoryCache({
    typePolicies: {
      Query: {
        fields: {
          userTasks: offsetLimitPagination(['listIds', 'sectionIds', 'priorities', 'statuses', 'recons', 'entities']),
          task: {
            merge(_existing, incoming) {
              const reactiveTasksData = reactiveTasksDataVar();
              const reactiveTasksQueryInfo = reactiveTasksQueryInfoVar();

              // it's possible these slice methods cause an undefined error if the task is somehow loaded
              // before the arrays are initialized
              const tasksCopy = reactiveTasksData.tasks.slice();
              const tasksBySectionCopy = reactiveTasksData.tasksBySection.slice();
              let tasksByDueDateCopy = reactiveTasksData.tasksByDueDate.slice();

              const taskFragment = client.readFragment({
                id: incoming.__ref,
                fragment: DETAILED_TASK_INFO,
                fragmentName: 'DetailedTaskInfo'
              });

              // if the task is a root task
              if (taskFragment && !taskFragment.parentId && tasksCopy.length) {
                // TODO: Clean up this mess
                const taskIndex = tasksCopy.findIndex((task: any) => task.id === taskFragment.id)
                let sectionIndex;

                if (taskFragment?.section) {
                  sectionIndex = tasksBySectionCopy.findIndex((section: any) => (section.id === taskFragment.section.id) && (section.listId === taskFragment.list.id));
                } else {
                  sectionIndex = tasksBySectionCopy.findIndex((section: any) => (section.id === undefined) && (section.listId === taskFragment.list.id));
                }

                // Update all task group by section to includes new tasks
                if (sectionIndex !== -1) {
                  if (taskIndex !== -1) {
                    tasksCopy[taskIndex] = { ...tasksCopy[taskIndex], ...taskFragment }
                    const taskInSectionIndex = tasksBySectionCopy[sectionIndex].tasks.findIndex((task: any) => task.id === taskFragment.id);
                    tasksBySectionCopy[sectionIndex].tasks[taskInSectionIndex] = { ...tasksBySectionCopy[sectionIndex].tasks[taskIndex], ...taskFragment };
                  } else {
                    tasksCopy.push(taskFragment);
                    tasksBySectionCopy[sectionIndex]?.tasks.push(taskFragment);
                  }
                }

                // Update the tasks in place
                // Cache should update locally before this merge function is called
                tasksByDueDateCopy = tasksByDueDateCopy.map((dueDateGroup: any) => {
                  return {
                    ...dueDateGroup,
                    tasks: dueDateGroup.tasks.map((task: any) => {
                      if (task.id === taskFragment.id) {
                        return {
                          ...task,
                          ...taskFragment
                        }
                      }

                      return task;
                    })
                  }
                })

                const taskModifiedDate = new Date((taskFragment.modifiedAtTime * 1000))
                if (isRecentlyUpdated(taskModifiedDate)) {
                  if (!tasksByDueDateCopy[0].tasks.some((task: any) => task.id === taskFragment.id)) {
                    tasksByDueDateCopy[0].tasks.push(taskFragment);
                  }
                }

                // selectedTask check here if user closes sidepanel before the call finishes
                reactiveTasksDataVar({
                  ...reactiveTasksData,
                  tasks: tasksCopy,
                  tasksBySection: tasksBySectionCopy,
                  tasksByDueDate: tasksByDueDateCopy,
                  selectedTask: reactiveTasksData.selectedTask ? { ...reactiveTasksData.selectedTask, ...tasksCopy.find((task: any) => task.id === taskFragment.id) } : null
                });

                reactiveTasksQueryInfoVar({
                  ...reactiveTasksQueryInfo,
                  selectedTaskLoading: false
                })
              }

              // if the task is a subtask
              if (taskFragment && taskFragment.parentId && tasksCopy.length) {
                let currentSelectedTask = reactiveTasksData.selectedTask;
                let sectionIndex;

                if (taskFragment?.section) {
                  sectionIndex = tasksBySectionCopy.findIndex((section: any) => (section.id === taskFragment?.section.id) && (section.listId === taskFragment?.list.id));
                } else {
                  sectionIndex = tasksBySectionCopy.findIndex((section: any) => (section.id === undefined) && (section.listId === taskFragment?.list.id));
                }
        
                const result = updateSubTaskInDeep(tasksCopy, taskFragment);
                if (result && result.rootIndex !== -1) {
                  tasksCopy[result.rootIndex] = result.rootTaskUpdated;
                }

                if (tasksBySectionCopy[sectionIndex]) {
                  const updatedTasks = tasksBySectionCopy[sectionIndex].tasks;
                  const result = updateSubTaskInDeep(tasksBySectionCopy[sectionIndex].tasks, taskFragment);
                  if (result && result.rootIndex !== -1) {
                    tasksBySectionCopy[sectionIndex].tasks[result.rootIndex] = result.rootTaskUpdated;
                  }
                }
        
                tasksByDueDateCopy = tasksByDueDateCopy.map((dueDateGroup: any) => {
                  const result = updateSubTaskInDeep(dueDateGroup.tasks, taskFragment);
        
                  return {
                    ...dueDateGroup,
                    tasks: dueDateGroup.tasks.map((task: any) => {
                      if (task.id === result?.rootTaskUpdated?.id) {
                        return {
                          ...task,
                          ...result.rootTaskUpdated
                        }
                      }

                      return task;
                    })
                  }
                });

                if (currentSelectedTask) {
                  if (currentSelectedTask.id === taskFragment.id) {
                    const oldStatus = currentSelectedTask.status;
                    currentSelectedTask = {...currentSelectedTask, ...taskFragment};
                    // if the sub task status has been updated to completed, update all sub tasks to completed status
                    if (taskFragment.status && oldStatus !== taskFragment.status && taskFragment.status === currentSelectedTask.completedStatusKey) {
                      // Update all sub tasks to completed status
                      if (currentSelectedTask.subTasks?.length) {
                        currentSelectedTask = {
                          ...currentSelectedTask,
                          subTasks: updateStatusForAllSubTasks(currentSelectedTask.subTasks, taskFragment.status)
                        }
                      }
                    }
                  } else {
                    const result = updateSubTaskInDeep([currentSelectedTask], taskFragment);
                    if (result && result.rootIndex !== -1) {
                      currentSelectedTask = {
                        ...currentSelectedTask,
                        ...result.rootTaskUpdated
                      }
                    }
                  }
                }

                // selectedTask check here if user closes sidepanel before the call finishes
                reactiveTasksDataVar({
                  ...reactiveTasksData,
                  tasks: tasksCopy,
                  tasksBySection: tasksBySectionCopy,
                  tasksByDueDate: tasksByDueDateCopy,
                  selectedTask: currentSelectedTask
                });

                reactiveTasksQueryInfoVar({
                  ...reactiveTasksQueryInfo,
                  selectedTaskLoading: false
                })
              }
            }
          },
          /**
           * This needs massively refactored
           */
          taskPageUserTasks: {
            merge(existing, incoming, options) {
              const tasks = existing ? existing.slice() : [];

              const reactiveTasksData = reactiveTasksDataVar();
              const reactiveTasksQueryInfo = reactiveTasksQueryInfoVar();
              // Decided to add the new fields to the old fields here
              // Adding the new version of tasks each time was causing data to be reset
              const tasksCopy = reactiveTasksData?.tasks?.slice() || [];
              const tasksBySectionCopy = reactiveTasksData?.tasksBySection?.slice() || [];
              const tasksByDueDateCopy = reactiveTasksData?.tasksByDueDate?.slice() || [];

              const newTasks: any = []

              // Gather all of the tasks for the incoming refs
              // I wish there was a better way to do this, but not yet unfortunately
              incoming.edges.forEach((edge: any) => {
                let taskFragment = client.readFragment({
                  id: edge.node.__ref,
                  fragment: buildDynamicTaskFragment(options.variables),
                  fragmentName: 'TaskCacheFragment'
                });

                if (taskFragment) {
                  const existingTaskIndex = tasks.findIndex((task: any) => task.id === taskFragment.id);

                  if (existingTaskIndex !== -1) {
                    tasks[existingTaskIndex] = taskFragment;
                  } else {
                    tasks.push(taskFragment);
                    tasksCopy.push(taskFragment);
                    newTasks.push(taskFragment)
                  }
                }
              });

              // const tasksGroupedBySection = groupBy(Object.values(tasks), (task: any) => [
              //   idx(task, t => t.list.name),
              // ]);

              // Need to move this to a helper function in the future
              // const tasksGroupedBySection: any = [];

              // Might need to sort by list rank or id
              newTasks.forEach((newTask: any) => {
                let sectionIndex;

                if (newTask?.section) {
                  sectionIndex = tasksBySectionCopy.findIndex((section: any) => (section.id === newTask.section.id) && (section.listId === newTask.list.id));
                } else {
                  sectionIndex = tasksBySectionCopy.findIndex((section: any) => (section.id === undefined) && (section.listId === newTask.list.id));
                }

                if (sectionIndex !== -1) {
                  tasksBySectionCopy[sectionIndex].tasks.push(newTask)
                } else {
                  const newSection = {
                    id: newTask?.section?.id,
                    name: newTask?.section?.name || 'No Section',
                    listId: newTask?.list?.id,
                    listName: newTask?.list?.name,
                    listRank: newTask?.list?.rank,
                    currentUserIsOwner: newTask?.list?.currentUserIsOwner || false,
                    tasks: [newTask],
                    newTaskActive: false
                  };

                  tasksBySectionCopy.push(newSection);
                }

                const taskModifiedDate = new Date((newTask.modifiedAtTime * 1000))
                if (isRecentlyUpdated(taskModifiedDate)) {
                  tasksByDueDateCopy[0].tasks.push(newTask);
                }

                const matchingDueDateGroups = getDueDateGroups(newTask.dueDate);

                matchingDueDateGroups.forEach((matchingDueDateGroupType: any) => {
                  const matchingDueDateGroup = tasksByDueDateCopy.find((dueDateGroup: any) => dueDateGroup.type === matchingDueDateGroupType);

                  matchingDueDateGroup.tasks.push(newTask);
                })
              });

              tasksBySectionCopy.sort((sectionA: any, sectionB: any) => {
                if (sectionA.listRank === sectionB.listRank) {
                  // sorts the section by the lowest task list number
                  if (sectionA.tasks?.length === 0 && sectionB.tasks?.length === 0) {
                    return 0;
                  };

                  if (sectionA.tasks?.length === 0) {
                    return 1;
                  }

                  if (sectionB.tasks?.length === 0) {
                    return -1;
                  }
                  const sectionALowestListNumber = sectionA.tasks.map((task: any) => task.listNumber).reduce((prevListNumber: number, nextListNumber: number) => Math.min(prevListNumber, nextListNumber));
                  const sectionBLowestListNumber = sectionB.tasks.map((task: any) => task.listNumber).reduce((prevListNumber: number, nextListNumber: number) => Math.min(prevListNumber, nextListNumber));

                  return sectionALowestListNumber - sectionBLowestListNumber;
                } else {
                  return sectionA.listRank - sectionB.listRank
                }
              })

              reactiveTasksDataVar({
                ...reactiveTasksData,
                tasks: tasksCopy,
                tasksBySection: tasksBySectionCopy,
                tasksByDueDate: tasksByDueDateCopy
              });

              reactiveTasksQueryInfoVar({
                ...reactiveTasksQueryInfo,
                tasksCursor: incoming.pageInfo.hasNextPage ? incoming.edges[incoming.edges.length - 1].cursor : null,
                tasksLoading: incoming.pageInfo.hasNextPage
              })

              return tasks;
            },

            read(existing) {
              return existing;
            }
          },
          taskPageUserTasksRemainingFields: {
            merge(existing, incoming, options) {
              const tasks = existing ? existing.slice() : [];

              const reactiveTasksData = reactiveTasksDataVar();
              let tasksCopy = reactiveTasksData?.tasks?.slice() || [];
              let tasksBySectionCopy = reactiveTasksData?.tasksBySection?.slice() || [];
              let tasksByDueDateCopy = reactiveTasksData?.tasksByDueDate?.slice() || [];

              incoming.edges.forEach((edge: any) => {
                const taskFragment = client.readFragment({
                  id: edge.node.__ref,
                  fragment: buildDynamicTaskFragment(options.variables),
                  fragmentName: 'TaskCacheFragment'
                });

                if (taskFragment) {
                  const existingTaskIndex = tasks.findIndex((task: any) => task.id === taskFragment?.id);
                  if (existingTaskIndex !== -1) {
                    tasks[existingTaskIndex] = taskFragment;
                  } else {
                    tasks.push(taskFragment);
                  }
                  const taskIndex = tasksCopy.findIndex((task: any) => task.id === taskFragment?.id);
                  tasksCopy[taskIndex] = { ...tasksCopy[taskIndex], ...taskFragment };
                  tasksBySectionCopy = tasksBySectionCopy.map((section: any) => {
                    return {
                      ...section,
                      tasks: section.tasks.map((task: any) => {
                        if (task.id === taskFragment?.id) {
                          return { ...task, ...taskFragment }
                        }
                        return task;
                      })
                    }
                  });
                  tasksByDueDateCopy = tasksByDueDateCopy.map((dueGroup: any) => {
                    return {
                      ...dueGroup,
                      tasks: dueGroup.tasks.map((task: any) => {
                        if (task.id === taskFragment?.id) {
                          return { ...task, ...taskFragment }
                        }
                        return task;
                      })
                    }
                  });
                }
              });
              reactiveTasksDataVar({
                ...reactiveTasksData,
                tasks: tasksCopy,
                tasksBySection: tasksBySectionCopy,
                tasksByDueDate: tasksByDueDateCopy
              });
              return tasks;
            },
            read(existing) {
              return existing;
            }
          },
          taskPageUserSubTasks: {
            merge(existing, incoming, options) {
              const tasks = existing ? existing.slice() : [];

              const reactiveTasksData = reactiveTasksDataVar();
              const reactiveSubTasksQueryInfo = reactiveSubTasksQueryInfoVar();

              let tasksCopy = reactiveTasksData?.tasks?.slice() || [];
              let tasksBySectionCopy = reactiveTasksData?.tasksBySection?.slice() || [];
              let tasksByDueDateCopy = reactiveTasksData?.tasksByDueDate?.slice() || [];

              incoming.edges.forEach((edge: any) => {
                const taskFragment = client.readFragment({
                  id: edge.node.__ref,
                  fragment: buildDynamicSubTaskFragment(options.variables),
                  fragmentName: 'SubTaskCacheFragment'
                });

                if (taskFragment) {
                  const existingTaskIndex = tasks.findIndex((task: any) => task.id === taskFragment?.id);
                  if (existingTaskIndex !== -1) {
                    tasks[existingTaskIndex] = taskFragment;
                  } else {
                    tasks.push(taskFragment);
                  }

                  // Update the sub task in the group by none
                  const parentTaskIndexSub = tasksCopy.findIndex((task: any) => task.id === taskFragment?.parentId);
                  const existingSubTaskSub = tasksCopy.find((task: any) => task.id === taskFragment.id);
                  const newTask = existingSubTaskSub ? { ...existingSubTaskSub, ...taskFragment } : taskFragment;
                  if (parentTaskIndexSub !== -1) {
                    const subTasksDisplayedCount = tasksCopy.filter((task: any) => task.parentId === taskFragment?.parentId).length;
                    // insert the subtask right after the parent task
                    tasksCopy.splice(parentTaskIndexSub + subTasksDisplayedCount + 1, 0, newTask);
                  } else {
                    tasksCopy.push(newTask);
                  }

                  const parentTaskIndex = tasksCopy.findIndex((task: any) => task.id === taskFragment?.parentId);
                  const existingSubTask = tasksCopy[parentTaskIndex]?.subTasks?.find((subTask: any) => subTask.id === taskFragment.id);
                  tasksCopy[parentTaskIndex] = { ...tasksCopy[parentTaskIndex], subTasks: existingSubTask ? tasksCopy[parentTaskIndex].subTasks.map((st:any) => { return st.id === taskFragment.id ? {...st, ...taskFragment} : st }) : (tasksCopy[parentTaskIndex].subTasks || []).concat(taskFragment)};

                  tasksBySectionCopy = tasksBySectionCopy.map((section: any) => {
                    return {
                      ...section,
                      tasks: section.tasks.map((task: any) => {
                        if (task.id === taskFragment?.parentId) {
                          const existingSubTask = task.subTasks?.find((subTask: any) => subTask.id === taskFragment.id);
                          return { ...task, subTasks: existingSubTask ? task.subTasks.map((st:any) => { return st.id === taskFragment.id ? {...st, ...taskFragment} : st }) : (task.subTasks || []).concat(taskFragment) }
                        }
                        return task;
                      })
                    }
                  });

                  const taskModifiedDate = new Date((taskFragment.modifiedAtTime * 1000))
                  if (isRecentlyUpdated(taskModifiedDate)) {
                    const existingSubTask = tasksByDueDateCopy[0].tasks.find((task: any) => task.id === taskFragment.id);
                    if (!existingSubTask) {
                      tasksByDueDateCopy[0].tasks.push(taskFragment);
                    }
                  }

                  const matchingDueDateGroups = getDueDateGroups(taskFragment.dueDate);

                  matchingDueDateGroups.forEach((matchingDueDateGroupType: any) => {
                    const matchingDueDateGroup = tasksByDueDateCopy.find((dueDateGroup: any) => dueDateGroup.type === matchingDueDateGroupType);
                    const parentTaskIndex = matchingDueDateGroup.tasks.findIndex((task: any) => task.id === taskFragment?.parentId);
                    const existingSubTask = matchingDueDateGroup.tasks.find((task: any) => task.id === taskFragment.id);
                    const newTask = existingSubTask ? { ...existingSubTask, ...taskFragment } : taskFragment;
                    if (parentTaskIndex !== -1) {
                      const subTasksDisplayedCount = matchingDueDateGroup.tasks.filter((task: any) => task.parentId === taskFragment?.parentId).length;
                      // insert the subtask right after the parent task
                      matchingDueDateGroup.tasks.splice(parentTaskIndex + subTasksDisplayedCount + 1, 0, newTask);
                    } else {
                      matchingDueDateGroup.tasks.push(newTask);
                    }
                  });

                  tasksByDueDateCopy = tasksByDueDateCopy.map((dueGroup: any) => {
                    return {
                      ...dueGroup,
                      tasks: dueGroup.tasks.map((task: any) => {
                        if (task.id === taskFragment?.parentId) {
                          const existingSubTask = task.subTasks?.find((subTask: any) => subTask.id === taskFragment.id);
                          return { ...task, subTasks: existingSubTask ? task.subTasks.map((st:any) => { return st.id === taskFragment.id ? {...st, ...taskFragment} : st }) : (task.subTasks || []).concat(taskFragment)}
                        }
                        return task;
                      })
                    }
                  });
                }
              });

              reactiveTasksDataVar({
                ...reactiveTasksData,
                tasks: tasksCopy,
                tasksBySection: tasksBySectionCopy,
                tasksByDueDate: tasksByDueDateCopy
              });

              reactiveSubTasksQueryInfoVar({
                ...reactiveSubTasksQueryInfo,
                tasksCursor: incoming.pageInfo.hasNextPage ? incoming.edges[incoming.edges.length - 1].cursor : null,
                tasksLoading: incoming.pageInfo.hasNextPage,
                parentIds: options.variables?.parentIds
              });

              return tasks;
            },

            read(existing) {
              return existing;
            }
          },
          taskPageUserSubTasksRemainingFields: {
            merge(existing, incoming, options) {
              const tasks = existing ? existing.slice() : [];

              const reactiveTasksData = reactiveTasksDataVar();
              let tasksCopy = reactiveTasksData?.tasks?.slice() || [];
              let tasksBySectionCopy = reactiveTasksData?.tasksBySection?.slice() || [];
              let tasksByDueDateCopy = reactiveTasksData?.tasksByDueDate?.slice() || [];

              incoming.edges.forEach((edge: any) => {
                const taskFragment = client.readFragment({
                  id: edge.node.__ref,
                  fragment: buildDynamicSubTaskFragment(options.variables),
                  fragmentName: 'SubTaskCacheFragment'
                });

                if (taskFragment) {
                  const existingTaskIndex = tasks.findIndex((task: any) => task.id === taskFragment?.id);
                  if (existingTaskIndex !== -1) {
                    tasks[existingTaskIndex] = taskFragment;
                  } else {
                    tasks.push(taskFragment);
                  }

                  // Update the sub task in the group by none
                  const taskIndex = tasksCopy.findIndex((task: any) => task.id === taskFragment?.id);
                  if (taskIndex !== -1) {
                    tasksCopy[taskIndex] = { ...tasksCopy[taskIndex], ...taskFragment };
                  } else {
                    const parentTaskIndex = tasksCopy.findIndex((task: any) => task.id === taskFragment?.parentId);
                    if (parentTaskIndex !== -1) {
                      const subTasksDisplayedCount = tasksCopy.filter((task: any) => task.parentId === taskFragment?.parentId).length;
                      // insert the subtask right after the parent task
                      tasksCopy.splice(parentTaskIndex + subTasksDisplayedCount + 1, 0, taskFragment);
                    } else {
                      tasksCopy.push(taskFragment);
                    }
                  }

                  const parentTaskIndex = tasksCopy.findIndex((task: any) => task.id === taskFragment?.parentId);
                
                  tasksCopy[parentTaskIndex] = { ...tasksCopy[parentTaskIndex], subTasks: (tasksCopy[parentTaskIndex].subTasks || []).map((subTask: any) => {
                    if (subTask.id === taskFragment.id) {
                      return { ...subTask, ...taskFragment, subTasks: (subTask.subTasks || []).map((st: any) => {
                        const existingSubTask = taskFragment.subTasks?.find((subTask: any) => subTask.id === st.id);
                        return existingSubTask ? { ...st, ...existingSubTask } : st;
                      })}
                    }
                    return subTask;
                  })};

                  tasksBySectionCopy = tasksBySectionCopy.map((section: any) => {
                    return {
                      ...section,
                      tasks: section.tasks.map((task: any) => {
                        if (task.id === taskFragment?.parentId) {
                          return { ...task, subTasks: (task.subTasks || []).map((subTask: any) => {
                              if (subTask.id === taskFragment.id) {
                                return { ...subTask, ...taskFragment, subTasks: (subTask.subTasks || []).map((st: any) => {
                                  const existingSubTask = taskFragment.subTasks?.find((subTask: any) => subTask.id === st.id);
                                  return existingSubTask ? { ...st, ...existingSubTask } : st;
                                })}
                              }
                              return subTask;
                            })
                          }
                        }
                        return task;
                      })
                    }
                  });

                  tasksByDueDateCopy = tasksByDueDateCopy.map((dueGroup: any) => {
                    return {
                      ...dueGroup,
                      tasks: dueGroup.tasks.map((task: any) => {
                        if (task.id === taskFragment?.parentId) {
                          return { ...task, subTasks: (task.subTasks || []).map((subTask: any) => {
                              if (subTask.id === taskFragment.id) {
                                return { ...subTask, ...taskFragment, subTasks: (subTask.subTasks || []).map((st: any) => {
                                  const existingSubTask = taskFragment.subTasks?.find((subTask: any) => subTask.id === st.id);
                                  return existingSubTask ? { ...st, ...existingSubTask } : st;
                                })}
                              }
                              return subTask;
                            })
                          }
                        }
                        return task;
                      })
                    }
                  });

                  // Update the sub task as root due date grouping
                  tasksByDueDateCopy = tasksByDueDateCopy.map((dueGroup: any) => {
                    return {
                      ...dueGroup,
                      tasks: dueGroup.tasks.map((task: any) => {
                        if (task.id === taskFragment?.id) {
                          return {
                            ...task,
                            ...taskFragment
                          }
                        }
                        return task;
                      })
                    }
                  });
                }
              });
              reactiveTasksDataVar({
                ...reactiveTasksData,
                tasks: tasksCopy,
                tasksBySection: tasksBySectionCopy,
                tasksByDueDate: tasksByDueDateCopy
              });
              return tasks;
            },

            read(existing) {
              return existing;
            }
          },
        }
      }
    }
  });
  const client = new ApolloClient<NormalizedCacheObject>({
    cache,
    connectToDevTools: true,
    link: from([link]),
    queryDeduplication: false,
    defaultOptions: {
      watchQuery: {
        fetchPolicy: 'network-only',
        errorPolicy: 'ignore',
      },
      query: {
        fetchPolicy: 'network-only',
        errorPolicy: 'all',
      }
    }
  });

  render(
    <Provider>
      <ApolloProvider client={client}>
        <ThemeProvider theme={theme}>
          <CssBaseline />
          {/*
            The Auth0ProviderWithHistory component has been introduced to wrap
            the App component by the `react-auth0` library used for the Okta authentication SSO.
          */}
          <Auth0ProviderWithHistory>
            <App />
          </Auth0ProviderWithHistory>
        </ThemeProvider>
      </ApolloProvider>
    </Provider>,
    document.body.appendChild(document.createElement('div'))
  );
});

export const reactiveTasksDataVar: any = makeVar({
  tasks: [],
  tasksBySection: [],
  tasksByDueDate: [
    {
      type: DueDateGroupingEnum.RECENTLY_UPDATED,
      name: 'Recently updated',
      tableRank: 0,
      tasks: []
    },
    {
      type: DueDateGroupingEnum.LATE,
      name: 'Past due',
      tableRank: 1,
      tasks: []
    },
    {
      type: DueDateGroupingEnum.TODAY,
      name: 'Due today',
      tableRank: 2,
      tasks: []
    },
    {
      type: DueDateGroupingEnum.TOMORROW,
      name: 'Due tomorrow',
      tableRank: 3,
      tasks: []
    },
    {
      type: DueDateGroupingEnum.THIS_WEEK,
      name: 'Due this week',
      tableRank: 4,
      tasks: []
    },
    {
      type: DueDateGroupingEnum.LATER,
      name: 'Due later',
      tableRank: 5,
      tasks: []
    }
  ],
  selectedTask: null
})

export const reactiveTasksQueryInfoVar: any = makeVar({
  tasksCursor: null,
  tasksLoading: false,
  selectedTaskLoading: false
})

export const reactiveSubTasksQueryInfoVar: any = makeVar({
  tasksCursor: null,
  tasksLoading: false,
  selectedTaskLoading: false,
  parentIds: []
})

const isRecentlyUpdated = (taskModifiedDate: Date) => {
  const currentDate = new Date();
  const hoursSinceModified = Math.abs((currentDate.getTime() - taskModifiedDate.getTime()) / (1000 * 60 * 60));
  return hoursSinceModified <= 72;
}

export const getDueDateGroups = (taskDueDateString: string | null) => {
  if (!taskDueDateString) return [DueDateGroupingEnum.LATER];
  const matchingDueDateGroups: string[] = [];
  const currentDate = new Date();
  const currentDateWithoutTime = new Date(currentDate.toDateString());

  const taskDueDate = new Date(taskDueDateString);

  const taskDueDateWithoutTime = new Date(taskDueDate.toDateString());
  const daysFromDueDate = Math.floor((taskDueDateWithoutTime.getTime() - currentDateWithoutTime.getTime()) / (1000 * 60 * 60 * 24));

  if (daysFromDueDate < 0) matchingDueDateGroups.push(DueDateGroupingEnum.LATE);
  if (daysFromDueDate >= 0 && daysFromDueDate < 1) matchingDueDateGroups.push(DueDateGroupingEnum.TODAY);
  if (daysFromDueDate >= 1 && daysFromDueDate < 2) matchingDueDateGroups.push(DueDateGroupingEnum.TOMORROW);
  if (daysFromDueDate >= 2 && daysFromDueDate < 7) matchingDueDateGroups.push(DueDateGroupingEnum.THIS_WEEK);
  if (daysFromDueDate >= 7) matchingDueDateGroups.push(DueDateGroupingEnum.LATER);

  if (matchingDueDateGroups.length === 0) {
    matchingDueDateGroups.push(DueDateGroupingEnum.LATER);
  }

  return matchingDueDateGroups;
}
