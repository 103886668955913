import { createMutationHook, gql } from '../graphqlHelpers';
import { UpdateList, UpdateListVariables } from './__generated__/UpdateList';

const UpdateListMutation = createMutationHook<UpdateList, UpdateListVariables>(gql`
mutation UpdateList($list: ListAttributes!) {
    updateList(list: $list) {
      success
      errors {
        path
        message
      }
      list {
        id
        name
        multipleReviewers
        tasks {
          id
          status
          priority
          reconciliations
          isReconciled
          responderDelivered
          companyPosition
        }
        description
        deliverFileOnUpload
        schedule {
          id
          repeatType
          repeatNumber
          repeatInterval
          calendarType
          ends
          endsOn
          endsAfter
          selectedWeekday
          selectedMonthday
          selectedMonth
        }
        rollForwardSetting {
          copyRoles
          copyFiles
          copyInternalMessages
          copyExternalMessages
          copyDueDates
          copyStatuses
          copyProcessSteps
          copySchedules
          hideTask
        }
        owners {
          ... on User {
            id
            displayName
            profileUrl
            email
          }
          ... on Team {
            id
            name
            users {
              id
            }
          }
        }
        retentionPolicy {
          id
          name
          duration
        }
        estimatedEndDate
        estimatedStartDate
        taggingUsers {
          ... on User {
            id
            displayName
          }
          ... on Team {
            id
            name
          }
        }
        requesterCompany {
          id
          name
        }
        responderCompany {
          id
          name
        }
        labels {
          id
          name
          color
        }
        useShortSubTaskStatuses
      }
    }
  }
  
`);

export default UpdateListMutation;
