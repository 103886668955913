import { gql } from '../graphqlHelpers';

// Fragment for getting the child messages on the TaskMessage model
// Currently we request 25 levels of children messages
// We can increase or decrease this number according to our need.
export const RECURSIVE_MESSAGES_FRAGMENT = gql`
  fragment RecursiveMessages on TaskMessage {
    ...MessageFields
    childMessages {
      ...MessageFields
      childMessages {
        ...MessageFields
        childMessages {
          ...MessageFields
          childMessages {
            ...MessageFields
            childMessages {
              ...MessageFields
              childMessages {
                ...MessageFields
                childMessages {
                  ...MessageFields
                  childMessages {
                    ...MessageFields
                    childMessages {
                      ...MessageFields
                      childMessages {
                        ...MessageFields
                        childMessages {
                          ...MessageFields
                          childMessages {
                            ...MessageFields
                            childMessages {
                              ...MessageFields
                              childMessages {
                                ...MessageFields
                                childMessages {
                                  ...MessageFields
                                  childMessages {
                                    ...MessageFields
                                    childMessages {
                                      ...MessageFields
                                      childMessages {
                                        ...MessageFields
                                        childMessages {
                                          ...MessageFields
                                          childMessages {
                                            ...MessageFields
                                            childMessages {
                                              ...MessageFields
                                              childMessages {
                                                ...MessageFields
                                                childMessages {
                                                  ...MessageFields
                                                  childMessages {
                                                    ...MessageFields
                                                  }
                                                }
                                              }
                                            }
                                          }
                                        }
                                      }
                                    }
                                  }
                                }
                              }
                            }
                          }
                        }
                      }
                    }
                  }
                }
              }
            }
          }
        }
      }
    }
  }
`;

// Fragment for message field to query on TaskMessage model
export const MESSAGE_FIELDS_FRAGMENT = gql`
  fragment MessageFields on TaskMessage {
    id
    isPublic
    message
    createdAt
    task {
      id
      parentId
    }
    company {
      id
    }
    viewed
    user {
      id
      fullName
      displayName
      email
      profileUrl
    }
    resolveDate
    resolveUser {
      fullName
      profileUrl
    }
    resolveStatusOption {
      id
      description
      resolveObject {
        id
        description
      }
    }
    resolveStatusDescription
    isEdited
    labels {
      id
      name
      color
    }
  }
`;