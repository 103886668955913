import { gql } from '@apollo/client';

// Fragment for task
export const TASK_FIELDS_FRAGMENT = gql`
  fragment TaskFields on Task {
    id
    parentId
    totalSubTasks
    subTaskAllowedStatuses
    name @include(if: $include_name)
    description @include(if: $include_description)
    currency @include(if: $include_currency)
    hasFiles @include(if: $include_hasFiles)
    hasMessages @include(if: $include_hasMessages)
    companyPosition @include(if: $include_companyPosition)
    currentUserIsReviewer @include(if: $include_currentUserIsReviewer)
    responderDelivered @include(if: $include_responderDelivered)
    hasUnreadMessages @include(if: $include_hasUnreadMessages)
    modifiedAtText @include(if: $include_modifiedAtText)
    modifiedAtTime @include(if: $include_modifiedAtTime)
    startDate @include(if: $include_startDate)
    dueDate @include(if: $include_dueDate)
    status @include(if: $include_status)
    completedStatusKey @include(if: $include_completed_status_key)
    priority @include(if: $include_priority)
    primaryTotal @include(if: $include_primaryTotal)
    secondaryTotal @include(if: $include_secondaryTotal)
    primarySourceType @include(if: $include_primarySourceType)
    secondarySourceType @include(if: $include_secondarySourceType)
    primaryFileReconSources @include(if: $include_primaryFileReconSources) {
      id
      sourceType
      fileVersion {
        id
        fileName
        fileLocation
        alterable
      }
      name
      isCurrent
      description
      date
      value
      associatedTaskIds
    }
    secondaryFileReconSources @include(if: $include_secondaryFileReconSources) {
      id
      sourceType
      fileVersion {
        id
        fileName
        fileLocation
        alterable
      }
      name
      isCurrent
      description
      date
      value
      associatedTaskIds
    }
    reconcilingItems @include(if: $include_reconcilingItems) {
      id
      sourceType
      fileVersion {
        id
        fileName
        fileLocation
        alterable
      }
      name
      isCurrent
      description
      date
      value
      associatedTaskIds
    }
    reconcilingItemTotal @include(if: $include_reconcilingItemTotal)
    reconDifference @include(if: $include_reconDifference)
    reconciliations @include(if: $include_reconciliations)
    isReconciled @include(if: $include_isReconciled)
    hasUnseenFiles @include(if: $include_hasUnseenFiles)
    listNumber @include(if: $include_listNumber)
    isBlocked @include(if: $include_isBlocked)
    dependencies @include(if: $include_dependencies) {
      id
      relatedId
      relatedName
      relatedDueDate
      taskId
      taskName
      taskDueDate
      dueDays
      dueHours
      hasStartDate
      status
      isDependent
      relatedListNumber
      taskListNumber
    }
    section @include(if: $include_section) {
      id
      name
    }
    processSteps @include(if: $include_processSteps) {
      id
      taskId
      description
      position
      completed
      files {
        id
        fileName
        fileLocation
        viewed
        alterable
        updatedAt
      }
    }
    list @include(if: $include_list) {
      id
      multipleReviewers
      name
      rank
      reconciliations
      currentUserIsOwner
      deliverFileOnUpload
      requesterCompany {
        id
        name
      }
      responderCompany {
        id
        name
      }
      schedule {
        id
        repeatType
        calendarType  
        repeatInterval
      }
      estimatedStartDate
      estimatedEndDate
    }
    files @include(if: $include_files) {
      id
      fileName
      fileLocation
      viewed
      alterable
      updatedAt
      taskId
      createdAt
      isCheckedOut
      checkedOutBy{
        fullName
        displayName
      }
      checkedOutAt
      companyId
    }
    messages @include(if: $include_messages) {
      id
      isPublic
      message
      createdAt
      task {
        id
      }
      company {
        id
      }
      viewed
      user {
        id
        fullName
        displayName
        email
        profileUrl
      }
      resolveDate
      resolveUser {
        fullName
        profileUrl
      }
      resolveStatusOption {
        id
        description
        resolveObject {
          id
          description
        }
      }
      resolveStatusDescription
      isEdited
    }
    tags @include(if: $include_tags) {
      id
      name
    }
    classifications @include(if: $include_classifications) {
      id
      name
    }
    userOwners @include(if: $include_userOwners) {
      id
      displayName
      profileUrl
    }
    teamOwners @include(if: $include_teamOwners) {
      id
      name
    }
    firstUserReviewers @include(if: $include_firstUserReviewers) {
      id
      displayName
      profileUrl
    }
    secondUserReviewers @include(if: $include_secondUserReviewers) {
      id
      displayName
      profileUrl
    }
    firstTeamReviewers @include(if: $include_firstTeamReviewers) {
      id
      name
    }
    secondTeamReviewers @include(if: $include_secondTeamReviewers) {
      id
      name
    }
    schedule @include(if: $include_schedule) {
      id
      repeatType
      repeatNumber
      repeatInterval
      calendarType
      ends
      endsOn
      endsAfter
      selectedWeekday
      selectedMonthday
      selectedMonth
      selectedYearday
      selectedSecond
      nextRunningDate
    }
  }
`;

export const buildDynamicTaskFragment = (options: any) => {
  const includeOptions = {
    include_name: options?.include_name || false,
    include_description: options?.include_description || false,
    include_currency: options?.include_currency || false,
    include_hasFiles: options?.include_hasFiles || false,
    include_hasMessages: options?.include_hasMessages || false,
    include_companyPosition: options?.include_companyPosition || false,
    include_currentUserIsReviewer: options?.include_currentUserIsReviewer || false,
    include_responderDelivered: options?.include_responderDelivered || false,
    include_hasUnreadMessages: options?.include_hasUnreadMessages || false,
    include_modifiedAtText: options?.include_modifiedAtText || false,
    include_modifiedAtTime: options?.include_modifiedAtTime || false,
    include_startDate: options?.include_startDate || false,
    include_dueDate: options?.include_dueDate || false,
    include_status: options?.include_status || false,
    include_completed_status_key: options?.include_completed_status_key || false,
    include_priority: options?.include_priority || false,
    include_primaryTotal: options?.include_primaryTotal || false,
    include_secondaryTotal: options?.include_secondaryTotal || false,
    include_primarySourceType: options?.include_primarySourceType || false,
    include_secondarySourceType: options?.include_secondarySourceType || false,
    include_primaryFileReconSources: options?.include_primaryFileReconSources || false,
    include_secondaryFileReconSources: options?.include_secondaryFileReconSources || false,
    include_reconcilingItems: options?.include_reconcilingItems || false,
    include_reconcilingItemTotal: options?.include_reconcilingItemTotal || false,
    include_reconDifference: options?.include_reconDifference || false,
    include_reconciliations: options?.include_reconciliations || false,
    include_isReconciled: options?.include_isReconciled || false,
    include_hasUnseenFiles: options?.include_hasUnseenFiles || false,
    include_listNumber: options?.include_listNumber || false,
    include_isBlocked: options?.include_isBlocked || false,
    include_dependencies: options?.include_dependencies || false,
    include_section: options?.include_section || false,
    include_processSteps: options?.include_processSteps || false,
    include_list: options?.include_list || false,
    include_files: options?.include_files || false,
    include_messages: options?.include_messages || false,
    include_tags: options?.include_tags || false,
    include_classifications: options?.include_classifications || false,
    include_userOwners: options?.include_userOwners || false,
    include_teamOwners: options?.include_teamOwners || false,
    include_firstUserReviewers: options?.include_firstUserReviewers || false,
    include_secondUserReviewers: options?.include_secondUserReviewers || false,
    include_firstTeamReviewers: options?.include_firstTeamReviewers || false,
    include_secondTeamReviewers: options?.include_secondTeamReviewers || false,
    include_schedule: options?.include_schedule || false,

  };
  return gql`
    fragment TaskCacheFragment on Task {
      id
      parentId
      totalSubTasks
      subTaskAllowedStatuses
      name @include(if: ${includeOptions.include_name})
      description @include(if: ${includeOptions.include_description})
      currency @include(if: ${includeOptions.include_currency})
      hasFiles @include(if: ${includeOptions.include_hasFiles})
      hasMessages @include(if: ${includeOptions.include_hasMessages})
      companyPosition @include(if: ${includeOptions.include_companyPosition})
      currentUserIsReviewer @include(if: ${includeOptions.include_currentUserIsReviewer})
      responderDelivered @include(if: ${includeOptions.include_responderDelivered})
      hasUnreadMessages @include(if: ${includeOptions.include_hasUnreadMessages})
      modifiedAtText @include(if: ${includeOptions.include_modifiedAtText})
      modifiedAtTime @include(if: ${includeOptions.include_modifiedAtTime})
      startDate @include(if: ${includeOptions.include_startDate})
      dueDate @include(if: ${includeOptions.include_dueDate})
      status @include(if: ${includeOptions.include_status})
      completedStatusKey @include(if: ${includeOptions.include_completed_status_key})
      priority @include(if: ${includeOptions.include_priority})
      primaryTotal @include(if: ${includeOptions.include_primaryTotal})
      secondaryTotal @include(if: ${includeOptions.include_secondaryTotal})
      primarySourceType @include(if: ${includeOptions.include_primarySourceType})
      secondarySourceType @include(if: ${includeOptions.include_secondarySourceType})
      primaryFileReconSources @include(if: ${includeOptions.include_primaryFileReconSources}) {
        id
        sourceType
        fileVersion {
          id
          fileName
          fileLocation
          alterable
        }
        name
        isCurrent
        description
        date
        value
        associatedTaskIds
      }
      secondaryFileReconSources @include(if: ${includeOptions.include_secondaryFileReconSources}) {
        id
        sourceType
        fileVersion {
          id
          fileName
          fileLocation
          alterable
        }
        name
        isCurrent
        description
        date
        value
        associatedTaskIds
      }
      reconcilingItems @include(if: ${includeOptions.include_reconcilingItems}) {
        id
        sourceType
        fileVersion {
          id
          fileName
          fileLocation
          alterable
        }
        name
        isCurrent
        description
        date
        value
        associatedTaskIds
      }
      reconcilingItemTotal @include(if: ${includeOptions.include_reconcilingItemTotal})
      reconDifference @include(if: ${includeOptions.include_reconDifference})
      reconciliations @include(if: ${includeOptions.include_reconciliations})
      isReconciled @include(if: ${includeOptions.include_isReconciled})
      hasUnseenFiles @include(if: ${includeOptions.include_hasUnseenFiles})
      listNumber @include(if: ${includeOptions.include_listNumber})
      isBlocked @include(if: ${includeOptions.include_isBlocked})
      dependencies @include(if: ${includeOptions.include_dependencies}) {
        id
        relatedId
        relatedName
        relatedDueDate
        taskId
        taskName
        taskDueDate
        dueDays
        dueHours
        hasStartDate
        status
        isDependent
        relatedListNumber
        taskListNumber
      }
      section @include(if: ${includeOptions.include_section}) {
        id
        name
      }
      processSteps @include(if: ${includeOptions.include_processSteps}) {
        id
        taskId
        description
        position
        completed
        files {
          id
          fileName
          fileLocation
          viewed
          alterable
          updatedAt
        }
      }
      list @include(if: ${includeOptions.include_list}) {
        id
        multipleReviewers
        name
        rank
        reconciliations
        currentUserIsOwner
        deliverFileOnUpload
        requesterCompany {
          id
          name
        }
        responderCompany {
          id
          name
        }
        schedule {
          id
          repeatType
          calendarType  
          repeatInterval
        }
        estimatedStartDate
        estimatedEndDate
      }
      files @include(if: ${includeOptions.include_files}) {
        id
        fileName
        fileLocation
        viewed
        alterable
        updatedAt
        taskId
        createdAt
        isCheckedOut
        checkedOutBy{
          fullName
          displayName
        }
        checkedOutAt
        companyId
      }
      messages @include(if: ${includeOptions.include_messages}) {
        id
        isPublic
        message
        createdAt
        task {
          id
        }
        company {
          id
        }
        viewed
        user {
          id
          fullName
          displayName
          email
          profileUrl
        }
        resolveDate
        resolveUser {
          fullName
          profileUrl
        }
        resolveStatusOption {
          id
          description
          resolveObject {
            id
            description
          }
        }
        resolveStatusDescription
        isEdited
      }
      tags @include(if: ${includeOptions.include_tags}) {
        id
        name
      }
      classifications @include(if: ${includeOptions.include_classifications}) {
        id
        name
      }
      userOwners @include(if: ${includeOptions.include_userOwners}) {
        id
        displayName
        profileUrl
      }
      teamOwners @include(if: ${includeOptions.include_teamOwners}) {
        id
        name
      }
      firstUserReviewers @include(if: ${includeOptions.include_firstUserReviewers}) {
        id
        displayName
        profileUrl
      }
      secondUserReviewers @include(if: ${includeOptions.include_secondUserReviewers}) {
        id
        displayName
        profileUrl
      }
      firstTeamReviewers @include(if: ${includeOptions.include_firstTeamReviewers}) {
        id
        name
      }
      secondTeamReviewers @include(if: ${includeOptions.include_secondTeamReviewers}) {
        id
        name
      }
      schedule @include(if: ${includeOptions.include_schedule}) {
        id
        repeatType
        repeatNumber
        repeatInterval
        calendarType
        ends
        endsOn
        endsAfter
        selectedWeekday
        selectedMonthday
        selectedMonth
        selectedYearday
        selectedSecond
        nextRunningDate
      }
    }
  `
};

export const buildDynamicSubTaskFragment = (options: any) => {
  const includeOptions = {
    include_name: options?.include_name || false,
    include_description: options?.include_description || false,
    include_currency: options?.include_currency || false,
    include_hasFiles: options?.include_hasFiles || false,
    include_hasMessages: options?.include_hasMessages || false,
    include_companyPosition: options?.include_companyPosition || false,
    include_currentUserIsReviewer: options?.include_currentUserIsReviewer || false,
    include_responderDelivered: options?.include_responderDelivered || false,
    include_hasUnreadMessages: options?.include_hasUnreadMessages || false,
    include_modifiedAtText: options?.include_modifiedAtText || false,
    include_modifiedAtTime: options?.include_modifiedAtTime || false,
    include_startDate: options?.include_startDate || false,
    include_dueDate: options?.include_dueDate || false,
    include_status: options?.include_status || false,
    include_completed_status_key: options?.include_completed_status_key || false,
    include_priority: options?.include_priority || false,
    include_primaryTotal: options?.include_primaryTotal || false,
    include_secondaryTotal: options?.include_secondaryTotal || false,
    include_primarySourceType: options?.include_primarySourceType || false,
    include_secondarySourceType: options?.include_secondarySourceType || false,
    include_primaryFileReconSources: options?.include_primaryFileReconSources || false,
    include_secondaryFileReconSources: options?.include_secondaryFileReconSources || false,
    include_reconcilingItems: options?.include_reconcilingItems || false,
    include_reconcilingItemTotal: options?.include_reconcilingItemTotal || false,
    include_reconDifference: options?.include_reconDifference || false,
    include_reconciliations: options?.include_reconciliations || false,
    include_isReconciled: options?.include_isReconciled || false,
    include_hasUnseenFiles: options?.include_hasUnseenFiles || false,
    include_listNumber: options?.include_listNumber || false,
    include_isBlocked: options?.include_isBlocked || false,
    include_dependencies: options?.include_dependencies || false,
    include_section: options?.include_section || false,
    include_processSteps: options?.include_processSteps || false,
    include_list: options?.include_list || false,
    include_files: options?.include_files || false,
    include_messages: options?.include_messages || false,
    include_tags: options?.include_tags || false,
    include_classifications: options?.include_classifications || false,
    include_userOwners: options?.include_userOwners || false,
    include_teamOwners: options?.include_teamOwners || false,
    include_firstUserReviewers: options?.include_firstUserReviewers || false,
    include_secondUserReviewers: options?.include_secondUserReviewers || false,
    include_firstTeamReviewers: options?.include_firstTeamReviewers || false,
    include_secondTeamReviewers: options?.include_secondTeamReviewers || false,
    include_schedule: options?.include_schedule || false,

  };
  return gql`
    fragment SubTaskCacheFragment on Task {
      id
      parentId
      totalSubTasks
      subTaskAllowedStatuses
      name @include(if: ${includeOptions.include_name})
      description @include(if: ${includeOptions.include_description})
      currency @include(if: ${includeOptions.include_currency})
      hasFiles @include(if: ${includeOptions.include_hasFiles})
      hasMessages @include(if: ${includeOptions.include_hasMessages})
      companyPosition @include(if: ${includeOptions.include_companyPosition})
      currentUserIsReviewer @include(if: ${includeOptions.include_currentUserIsReviewer})
      responderDelivered @include(if: ${includeOptions.include_responderDelivered})
      hasUnreadMessages @include(if: ${includeOptions.include_hasUnreadMessages})
      modifiedAtText @include(if: ${includeOptions.include_modifiedAtText})
      modifiedAtTime @include(if: ${includeOptions.include_modifiedAtTime})
      startDate @include(if: ${includeOptions.include_startDate})
      dueDate @include(if: ${includeOptions.include_dueDate})
      status @include(if: ${includeOptions.include_status})
      completedStatusKey @include(if: ${includeOptions.include_completed_status_key})
      priority @include(if: ${includeOptions.include_priority})
      primaryTotal @include(if: ${includeOptions.include_primaryTotal})
      secondaryTotal @include(if: ${includeOptions.include_secondaryTotal})
      primarySourceType @include(if: ${includeOptions.include_primarySourceType})
      secondarySourceType @include(if: ${includeOptions.include_secondarySourceType})
      primaryFileReconSources @include(if: ${includeOptions.include_primaryFileReconSources}) {
        id
        sourceType
        fileVersion {
          id
          fileName
          fileLocation
          alterable
        }
        name
        isCurrent
        description
        date
        value
        associatedTaskIds
      }
      secondaryFileReconSources @include(if: ${includeOptions.include_secondaryFileReconSources}) {
        id
        sourceType
        fileVersion {
          id
          fileName
          fileLocation
          alterable
        }
        name
        isCurrent
        description
        date
        value
        associatedTaskIds
      }
      reconcilingItems @include(if: ${includeOptions.include_reconcilingItems}) {
        id
        sourceType
        fileVersion {
          id
          fileName
          fileLocation
          alterable
        }
        name
        isCurrent
        description
        date
        value
        associatedTaskIds
      }
      reconcilingItemTotal @include(if: ${includeOptions.include_reconcilingItemTotal})
      reconDifference @include(if: ${includeOptions.include_reconDifference})
      reconciliations @include(if: ${includeOptions.include_reconciliations})
      isReconciled @include(if: ${includeOptions.include_isReconciled})
      hasUnseenFiles @include(if: ${includeOptions.include_hasUnseenFiles})
      listNumber @include(if: ${includeOptions.include_listNumber})
      isBlocked @include(if: ${includeOptions.include_isBlocked})
      dependencies @include(if: ${includeOptions.include_dependencies}) {
        id
        relatedId
        relatedName
        relatedDueDate
        taskId
        taskName
        taskDueDate
        dueDays
        dueHours
        hasStartDate
        status
        isDependent
        relatedListNumber
        taskListNumber
      }
      section @include(if: ${includeOptions.include_section}) {
        id
        name
      }
      processSteps @include(if: ${includeOptions.include_processSteps}) {
        id
        taskId
        description
        position
        completed
        files {
          id
          fileName
          fileLocation
          viewed
          alterable
          updatedAt
        }
      }
      list @include(if: ${includeOptions.include_list}) {
        id
        multipleReviewers
        name
        rank
        reconciliations
        currentUserIsOwner
        deliverFileOnUpload
        requesterCompany {
          id
          name
        }
        responderCompany {
          id
          name
        }
        schedule {
          id
          repeatType
          calendarType  
          repeatInterval
        }
        estimatedStartDate
        estimatedEndDate
      }
      files @include(if: ${includeOptions.include_files}) {
        id
        fileName
        fileLocation
        viewed
        alterable
        updatedAt
        taskId
        createdAt
        isCheckedOut
        checkedOutBy{
          fullName
          displayName
        }
        checkedOutAt
        companyId
      }
      messages @include(if: ${includeOptions.include_messages}) {
        id
        isPublic
        message
        createdAt
        task {
          id
        }
        company {
          id
        }
        viewed
        user {
          id
          fullName
          displayName
          email
          profileUrl
        }
        resolveDate
        resolveUser {
          fullName
          profileUrl
        }
        resolveStatusOption {
          id
          description
          resolveObject {
            id
            description
          }
        }
        resolveStatusDescription
        isEdited
      }
      tags @include(if: ${includeOptions.include_tags}) {
        id
        name
      }
      classifications @include(if: ${includeOptions.include_classifications}) {
        id
        name
      }
      userOwners @include(if: ${includeOptions.include_userOwners}) {
        id
        displayName
        profileUrl
      }
      teamOwners @include(if: ${includeOptions.include_teamOwners}) {
        id
        name
      }
      firstUserReviewers @include(if: ${includeOptions.include_firstUserReviewers}) {
        id
        displayName
        profileUrl
      }
      secondUserReviewers @include(if: ${includeOptions.include_secondUserReviewers}) {
        id
        displayName
        profileUrl
      }
      firstTeamReviewers @include(if: ${includeOptions.include_firstTeamReviewers}) {
        id
        name
      }
      secondTeamReviewers @include(if: ${includeOptions.include_secondTeamReviewers}) {
        id
        name
      }
      schedule @include(if: ${includeOptions.include_schedule}) {
        id
        repeatType
        repeatNumber
        repeatInterval
        calendarType
        ends
        endsOn
        endsAfter
        selectedWeekday
        selectedMonthday
        selectedMonth
        selectedYearday
        selectedSecond
        nextRunningDate
      }
      subTasks {
        id
        parentId
        totalSubTasks
        subTaskAllowedStatuses
        name @include(if: ${includeOptions.include_name})
        description @include(if: ${includeOptions.include_description})
        currency @include(if: ${includeOptions.include_currency})
        hasFiles @include(if: ${includeOptions.include_hasFiles})
        hasMessages @include(if: ${includeOptions.include_hasMessages})
        companyPosition @include(if: ${includeOptions.include_companyPosition})
        currentUserIsReviewer @include(if: ${includeOptions.include_currentUserIsReviewer})
        responderDelivered @include(if: ${includeOptions.include_responderDelivered})
        hasUnreadMessages @include(if: ${includeOptions.include_hasUnreadMessages})
        modifiedAtText @include(if: ${includeOptions.include_modifiedAtText})
        modifiedAtTime @include(if: ${includeOptions.include_modifiedAtTime})
        startDate @include(if: ${includeOptions.include_startDate})
        dueDate @include(if: ${includeOptions.include_dueDate})
        status @include(if: ${includeOptions.include_status})
        completedStatusKey @include(if: ${includeOptions.include_completed_status_key})
        priority @include(if: ${includeOptions.include_priority})
        primaryTotal @include(if: ${includeOptions.include_primaryTotal})
        secondaryTotal @include(if: ${includeOptions.include_secondaryTotal})
        primarySourceType @include(if: ${includeOptions.include_primarySourceType})
        secondarySourceType @include(if: ${includeOptions.include_secondarySourceType})
        primaryFileReconSources @include(if: ${includeOptions.include_primaryFileReconSources}) {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
            alterable
          }
          name
          isCurrent
          description
          date
          value
          associatedTaskIds
        }
        secondaryFileReconSources @include(if: ${includeOptions.include_secondaryFileReconSources}) {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
            alterable
          }
          name
          isCurrent
          description
          date
          value
          associatedTaskIds
        }
        reconcilingItems @include(if: ${includeOptions.include_reconcilingItems}) {
          id
          sourceType
          fileVersion {
            id
            fileName
            fileLocation
            alterable
          }
          name
          isCurrent
          description
          date
          value
          associatedTaskIds
        }
        reconcilingItemTotal @include(if: ${includeOptions.include_reconcilingItemTotal})
        reconDifference @include(if: ${includeOptions.include_reconDifference})
        reconciliations @include(if: ${includeOptions.include_reconciliations})
        isReconciled @include(if: ${includeOptions.include_isReconciled})
        hasUnseenFiles @include(if: ${includeOptions.include_hasUnseenFiles})
        listNumber @include(if: ${includeOptions.include_listNumber})
        isBlocked @include(if: ${includeOptions.include_isBlocked})
        dependencies @include(if: ${includeOptions.include_dependencies}) {
          id
          relatedId
          relatedName
          relatedDueDate
          taskId
          taskName
          taskDueDate
          dueDays
          dueHours
          hasStartDate
          status
          isDependent
          relatedListNumber
          taskListNumber
        }
        section @include(if: ${includeOptions.include_section}) {
          id
          name
        }
        processSteps @include(if: ${includeOptions.include_processSteps}) {
          id
          taskId
          description
          position
          completed
          files {
            id
            fileName
            fileLocation
            viewed
            alterable
            updatedAt
          }
        }
        list @include(if: ${includeOptions.include_list}) {
          id
          multipleReviewers
          name
          rank
          reconciliations
          currentUserIsOwner
          deliverFileOnUpload
          requesterCompany {
            id
            name
          }
          responderCompany {
            id
            name
          }
          schedule {
            id
            repeatType
            calendarType  
            repeatInterval
          }
          estimatedStartDate
          estimatedEndDate
        }
        files @include(if: ${includeOptions.include_files}) {
          id
          fileName
          fileLocation
          viewed
          alterable
          updatedAt
          taskId
          createdAt
          isCheckedOut
          checkedOutBy{
            fullName
            displayName
          }
          checkedOutAt
          companyId
        }
        messages @include(if: ${includeOptions.include_messages}) {
          id
          isPublic
          message
          createdAt
          task {
            id
          }
          company {
            id
          }
          viewed
          user {
            id
            fullName
            displayName
            email
            profileUrl
          }
          resolveDate
          resolveUser {
            fullName
            profileUrl
          }
          resolveStatusOption {
            id
            description
            resolveObject {
              id
              description
            }
          }
          resolveStatusDescription
          isEdited
        }
        tags @include(if: ${includeOptions.include_tags}) {
          id
          name
        }
        classifications @include(if: ${includeOptions.include_classifications}) {
          id
          name
        }
        userOwners @include(if: ${includeOptions.include_userOwners}) {
          id
          displayName
          profileUrl
        }
        teamOwners @include(if: ${includeOptions.include_teamOwners}) {
          id
          name
        }
        firstUserReviewers @include(if: ${includeOptions.include_firstUserReviewers}) {
          id
          displayName
          profileUrl
        }
        secondUserReviewers @include(if: ${includeOptions.include_secondUserReviewers}) {
          id
          displayName
          profileUrl
        }
        firstTeamReviewers @include(if: ${includeOptions.include_firstTeamReviewers}) {
          id
          name
        }
        secondTeamReviewers @include(if: ${includeOptions.include_secondTeamReviewers}) {
          id
          name
        }
        schedule @include(if: ${includeOptions.include_schedule}) {
          id
          repeatType
          repeatNumber
          repeatInterval
          calendarType
          ends
          endsOn
          endsAfter
          selectedWeekday
          selectedMonthday
          selectedMonth
          selectedYearday
          selectedSecond
          nextRunningDate
        }
      }
    }
  `
};