import { FRAGMENT_DETAILED_TASK } from '../fragments/detailedTaskInfo';
import { MESSAGE_FIELDS_FRAGMENT, RECURSIVE_MESSAGES_FRAGMENT } from '../fragments/RecursiveMessages';
import { createMutationHook, gql } from '../graphqlHelpers';

export const useUpdateTask = createMutationHook(gql`
  mutation UpdateTask(
    $id: ID!
    $name: String
    $description: String
    $priority: String
    $status: String
    $dueDate: ISO8601DateTime
    $startDate: ISO8601DateTime
    $section: String
    $relativeDueDate: Int
    $relativeStartDate: Int
    $isAutomation: Boolean
    $scheduleRepeatType: String
  ) {
    updateTask(
      id: $id
      name: $name
      description: $description
      priority: $priority
      status: $status
      dueDate: $dueDate
      startDate: $startDate
      section: $section
      relativeDueDate: $relativeDueDate
      relativeStartDate: $relativeStartDate
      isAutomation: $isAutomation
      scheduleRepeatType: $scheduleRepeatType
    ) {
      success
      task {
        ...FragmentDetailedTask
        subTasks {
          ...FragmentDetailedTask
          subTasks {
            ...FragmentDetailedTask
          }
        }
      }
    }
  }
  ${FRAGMENT_DETAILED_TASK}
  ${RECURSIVE_MESSAGES_FRAGMENT}
  ${MESSAGE_FIELDS_FRAGMENT}
`);
