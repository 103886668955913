import React, { useEffect, useState } from 'react';
import idx from 'idx';
import clsx from 'clsx';
import axios from 'axios';
import { useHistory } from 'react-router-dom';
import {
  Paper,
  List,
  ClickAwayListener,
  ListItem,
  Button,
  Checkbox,
  FormControlLabel,
  Table,
  TableCell,
  TableHead,
  TableRow, Tooltip,
  TableBody,
  Typography
} from '@material-ui/core';
import { Check, ArrowDropDown } from '@material-ui/icons';
import Dropdown from '../common/Dropdown';
import { DatePicker } from '../common/DatePicker';
import InputForm from '../common/ListInputForm';
import { Header } from '../common/Header';
import { COLORS } from '../../constants/theme';
import { unique } from '../../helpers/helpers';
import { useStyles } from '../common/Style';
import CircularLoader from "../common/CircularLoader";

import { IntegrationValueSelection } from './IntegrationValueSelection';
import { IntegrationEnable } from './IntegrationEnable';
import { IntegrationAuthentication } from './IntegrationAuthentication';
import { sortingFunctionHelper } from './reconHelpers';

import { useReconSettings } from '../../graphql/queries/ReconSettings';
import { useUpdateListReconSettings } from '../../graphql/mutations/UpdateListReconSettings';
import { useUpdateTaskRecon } from '../../graphql/mutations/UpdateTaskRecon';
import { useUpdateTaskReconSettings } from '../../graphql/mutations/UpdateTaskReconSettings';
import { useBulkAliasAssign } from '../../graphql/mutations/BulkAliasAssign';
import { useBulkTaskReconAssign } from '../../graphql/mutations/BulkTaskReconAssign';
import { useUpdateTaskZeroBalance } from '../../graphql/mutations/UpdateTaskZeroBalance';
import {
  RECON_TYPES,
  VALUE_SOURCE_TYPES,
  INTACCT_SOURCE_TYPE_ID,
  LOCKED_INTACCT_SOURCE_COMPANIES,
  QBO_SOURCE_TYPE_ID,
  LOCKED_QBO_SOURCE_COMPANIES,
  NETSUITE_SOURCE_TYPE_ID,
  LOCKED_NETSUITE_SOURCE_COMPANIES
} from '../../constants/types';
import { useError } from '../../hooks/UseError';
import { useSuccess } from '../../hooks/UseSuccess';
import _ from 'lodash';
import { getCSRF } from '../../helpers/helpers';
import { intacct } from '../../helpers/api';
import { sl } from '../../helpers/api';
import { qbo } from '../../api/integrations';
import EntityBadges from '../migration/TaskList/EntityBadges';
import { useStateContext } from '../../store/index';
import { INTEGRATION_SOURCE_TYPE } from '../../constants/integration_source_type';

interface ReconSettingsProps {
  isNewProjectCreate?: boolean;
  isCreate?: boolean;
  updateList?: any;
  updateTask?: any;
  listData?: any;
  //tasks?: any[];
}

export function ReconSettings(props: any) {
  const error = useError();
  const success = useSuccess();
  const classes = useStyles();
  const history = useHistory();
  const state = useStateContext(); // TODO: This seems not used. Find a time to remove it properly.
  const updateTaskReconSettings = useUpdateTaskReconSettings();
  const updateListReconSettings = useUpdateListReconSettings();
  const bulkAliasAssign = useBulkAliasAssign();
  const bulkTaskReconAssign = useBulkTaskReconAssign();

  const updateTaskRecon = useUpdateTaskRecon();
  const [updateTaskZeroBalance, { data: updateTaskZeroBalanceData, loading: updateTaskZeroBalanceLoading, error: updateTaskZeroBalanceError }] = useUpdateTaskZeroBalance({});

  const [integrationSelected, setIntegrationSelected] = useState<any>('-1');
  const [sectionData, setSectionData] = useState<any>(null); // TODO: This seems not used. Find a time to remove it properly.
  const [integrationTask, setIntegrationTask] = useState<any>({});
  const [integrationSource, setIntegrationSource] = useState<string>('');
  const [showValueSelection, setShowValueSelection] = useState<boolean>(false);
  const [showEnable, setShowEnable] = useState<boolean>(false);
  const [showAuthentication, setShowAuthentication] = useState<boolean>(false);

  // filter / sorting state variables:
  const [sectionFilter, setSectionFilter] = useState<string[]>([]);
  const [nameSort, setNameSort] = useState<string | boolean>(false);
  const [dueDateSort, setDueDateSort] = useState<string | boolean>(false);
  const [differenceSort, setDifferenceSort] = useState<string | boolean>(false);
  const [activeSort, setActiveSort] = useState<string | false>(false);
  const [primarySort, setPrimarySort] = useState<string | boolean>(false);
  const [secondarySort, setSecondarySort] = useState<string | boolean>(false);

  // display state variables
  const [showSectionFilter, setShowSectionFilter] = useState<boolean>(false);

  // set loader for authenticateUser
  const [authenticateUserLoading, setAuthenticateUserLoading] = useState(false);

  const updateNameSort = () => {
    var newSort;
    switch (nameSort) {
      case false: newSort = 'ASC'; break;
      case 'ASC': newSort = 'DESC'; break;
      case 'DESC': newSort = false; break;
      default: newSort = false;
    }
    setNameSort(newSort);
    setActiveSort(newSort ? 'name' : false);
  }

  const updatePrimarySort = () => {
    let newSort;
    switch (primarySort) {
      case false: newSort = 'ASC'; break;
      case 'ASC': newSort = 'DESC'; break;
      case 'DESC': newSort = false; break;
      default: newSort = false;
    }
    setPrimarySort(newSort);
    setActiveSort(newSort ? 'primary' : false);
  }

  const [primaryListLabel, setPrimaryListLabel] = useState<string>('');
  const [secondaryListLabel, setSecondaryListLabel] = useState<string>('');
  const [listId, setListId] = useState<string>(
    props.isNewProjectCreate ? idx(props, p => p.listData.id) : idx(props, p => p.location.state.listId)
  );
  const reconSettings = useReconSettings({ listId });
  const [listData, setListData] = useState<any>(idx(props, p => p.listData));

  const listReconType = idx(listData, (l: any) =>
    RECON_TYPES.find((o: any) => o.id === l.reconciliations).name
  );

  //Check if the user is the current projectOwner
  //Need to find out what the list type is actually referring to here, hoping it's a project
  const currentUserIsOwner = idx(listData, (l: any) => l.currentUserIsOwner) || false;
  const tasks = idx(listData, (l: any) => l.tasks.filter((t:any) => !t.parentId)) || [];

  /* For section summary: flat array of unique sections */
  const sections = idx(tasks, (t: any) => {
    const mappedSections = t.map((i: any) => i.section && i.section.name)
      .filter((t: any) => Boolean(t));
    return unique(mappedSections);
  }) || [];

  useEffect(() => {
    const e = sections.map((s: any, i: number) => {
      const sectionTasks = tasks.filter((t: any) =>
        t.section && t.section.name === s
      );
      const activeRecons = sectionTasks.filter((t: any) =>
        t.reconciliations
      )
      const activeReconsAmount = activeRecons.length;
      const reconciledTaskAmount = activeRecons.filter((t: any) =>
        t.isReconciled
      ).length
      const shorten = s.length > 25 ? s.substring(0, 23) + '...' : s
      return ({
        date: shorten,
        rec: reconciledTaskAmount,
        non: activeReconsAmount - reconciledTaskAmount,
        tot: activeReconsAmount
      })
    }).sort((one: any, two: any) => {
      if (one.tot > two.tot) return -1;
      if (one.tot < two.tot) return 1;

      if (one.date > two.date) return -1;
      if (one.date < two.date) return 1;
      return 0;
    }).slice(0, 5).reverse();

    setSectionData(e);
  }, [reconSettings.data])

  const handleSectionFilterClick = (c: any) => {
    const section = c.props.children;
    sectionFilter.includes(section)
      ? setSectionFilter(sectionFilter.filter((a: string) => section !== a))
      : setSectionFilter([...sectionFilter, section])
  };

  useEffect(() => {
    if (location.href.includes('realmId')) {
      const urlParams = new URLSearchParams(window.location.search);
      const code = urlParams.get('code');
      const state = urlParams.get('state');
      qbo.callback(urlParams)
        .then((a: any) =>
          setListId(idx(sessionStorage, (s: any) => s.getItem('connectList')) || "")
        )
    }
  }, [location])

  useEffect(() => {
    if (!reconSettings.data || props.isCreate) { return; }
    const listData = idx(reconSettings.data, (d: any) => d.list);
    setListData(listData);
  }, [reconSettings.data])

  /* If project exists, update */
  /* task.reconciliations comes in as a string of a number */
  /* if isCreate is false this will be executed */
  const updateTask = (task: any) => {
    /* Update local DOM */
    const newListData = Object.assign({}, listData);
    const newTask = newListData.tasks.find((t: any) => t.id === task.id);
    newTask.reconciliations = !Number(newTask.reconciliations);
    setListData(newListData);
    /* Update BE*/
    const r = updateTaskReconSettings(task.id, newTask.reconciliations);
  }

  const handleTaskToggle = (task: any) => {
    props.isCreate
      ? props.updateTask({
        ...task,
        reconciliations: Boolean(Number(task.reconciliations)) ? '0' : '1'
      })
      : updateTask(task)
  }

  const launchAuthenticate = (integrationType: string) => {
    if (integrationSelected == '5') {
      sessionStorage.setItem('connectList', listId)
      axios.get('/connector/qbo/connect', {
        params: {
          list_id: listData.id
        }
      })
        .then((d: any) => window.location = d.data.url)
    }
    setShowValueSelection(false);
    setShowEnable(false);
    setShowAuthentication(true);
  }

  const launchEnable = (integrationType: string) => {
    setShowValueSelection(false);
    setShowAuthentication(false);
    setShowEnable(true);
  }

  // shows the Value Modal
  const launchValueSelection = (integrationType: string) => {
    setShowAuthentication(false);
    setShowEnable(false);
    setShowValueSelection(true);
  }

  /**
   * Need to setIntegrationSelected
   * to -1 cause IntegrationValueSelection component will continue to render the
   * IntacctValueSelection (integrationType will be 3) component but with open set to false,
   * So this is never destroying the IntacctValueSelection component
   * Hence the previous state is being preserved.
   */
  const closeModals = () => {
    setShowAuthentication(false);
    setShowEnable(false);
    setShowValueSelection(false);
    setIntegrationSelected(-1);
  }

  // We always want to show the 'instructions' modal if not already authed
  const launchAuthentication = (integrationType: string) => {
    launchEnable(integrationType)
  }

  /**
   * 
   * @param task Task object to be updated
   * @param sourceValue Id of the selected integration (1-6)
   * @param sourceType "primary" or "secondary"
   */
  const handleValueSourceChange = async (task: any, sourceValue: string, sourceType: string) => {

    setIntegrationSelected(sourceValue);

    if (sourceValue === '5') {
      setIntegrationTask(task);
      setIntegrationSource(sourceType);
      await qbo.validate(task.id)
        ? launchValueSelection('qbo')
        : launchAuthentication('QuickBooks Online')

    }
    if (sourceValue === '3') {
      // I don't like this
      setIntegrationTask(task);
      setIntegrationSource(sourceType);

      await intacct.validate()
        ? launchValueSelection('intacct')
        : launchAuthentication('intacct')
    } else if (sourceValue === '4') {
      setIntegrationTask(task);
      setIntegrationSource(sourceType);
      await sl.validate()
        ? launchValueSelection('sl')
        : launchAuthentication('sl')
    } else if (sourceValue === '6') {
      setIntegrationTask(task);
      setIntegrationSource(sourceType);
      launchValueSelection('ns');
    } else {

      const newListData = Object.assign({}, listData);
      const newTask = newListData.tasks.find((t: any) => t.id === task.id);
      newTask[`${sourceType}SourceType`] = sourceValue;
      setListData(newListData);

      props.isCreate
        ? props.updateTask({ ...task, [`${sourceType}SourceType`]: Number(sourceValue) })
        : updateTaskRecon({ taskId: task.id, sourceType: sourceType, valueSource: Number(sourceValue) });
    }
  }

  const handleAliasChange = (task: any, value: any, name: any, keyCode: any) => {
    if(keyCode === '13' || keyCode === 'click') {
      const newListData = Object.assign({}, listData);
      const newTask = newListData.tasks.find((t: any) => t.id === task.id);
      newTask[`${name}Label`] = value;
      setListData(newListData);

      props.isCreate
        ? props.updateTask({ ...task, [`${name}Label`]: value })
        : updateTaskRecon({taskId: task.id, alias: value, sourceType: name })
    }
  }

  const updateProjectReconType = (s: string) => {
    const newListData = Object.assign({}, listData, { reconciliations: s });
    // handle tasks
    // if the s is set to '0', all tasks, should also be set to 0
    const setTasks = updateOnTypeChange(newListData, listData.reconciliations);
    // if we go from '0' to something -- set all of the tasks to '1'
    setListData(setTasks);
    props.isCreate
      ? props.updateList('type', s)
      : updateListReconSettings({ id: listData.id, reconciliations: s })
  }

  /* When changing a Project-level reconciliation type, the underlying
  *  tasks may also need to change.
  *  @link to confluence */
  function updateOnTypeChange(newList: any, previousReconType: string) {
    if (newList.reconciliations === '0') {
      return Object.assign({
        ...newList, tasks: newList.tasks.map((t: any) => {
          return ({ ...t, reconciliations: '0' })
        })
      })
    }
    if (previousReconType === '0' && newList.reconciliations !== '0') {
      return Object.assign({
        ...newList, tasks: newList.tasks.map((t: any) => {
          // Do not activate recon for subtasks now
          if (t.parentId) {
            return ({ ...t, reconciliations: '0' })
          }
          return ({ ...t, reconciliations: '1' })
        })
      })
    }
    else { return newList }
  }

  const updateProjectReconDate = (d: Date) => {
    const newListData = Object.assign(listData, { reconEndDate: d });
    setListData(newListData);
    props.isCreate
      ? props.updateList('date', d)
      : updateListReconSettings({ id: listData.id, reconEndDate: d })
  }

  const navigateToReconSummary = () => {
    if (!listData.id) { return; }
    history.push({
      pathname: '/app/recon_summary',
      state: {
        listId: listData.id,
        lastPage: props.location.state.lastPage
      }
    });
  }

  const navigateToLastMainPage = () => {
    history.push(props.location.state.lastPage || '/app/lists');
  }

  const handleListLabel = (e: any) => {
    setListData({ ...listData, tasks: listData.tasks.map((l: any) => ({ ...l, [e.target.name]: e.target.value })) })
    props.isCreate
      ? props.updateAllTasks(e.target.name, e.target.value)
      : bulkAliasAssign({ listId: listData.id, sourceType: e.target.name.replace('Label', ''), sourceAlias: e.target.value })
  }

  const authenticateUser = (e: any) => {
    if (integrationSelected === '3') {
      setAuthenticateUserLoading(true);
      axios.post('/connector/intacct/connect', e, { headers: { 'X-CSRF-TOKEN': getCSRF() } })
        .then((r: any) => {
          setAuthenticateUserLoading(false);
          if (r.data.success) {
            success('Intacct Authenticated');
            launchValueSelection('intacct');
          } else {
            error('Invalid Credentials. Please try again or contact support')
          }
        })
        .catch((e: any) => {
          setAuthenticateUserLoading(false);
          console.error(e);
        });
    }
    if (integrationSelected === '4') {
      //sl.connect(e);
      axios.post('/connector/sl/connect', e, { headers: { 'X-CSRF-TOKEN': getCSRF() } })
        .then((r: any) => {
          if (r.data.success) {
            success('SoftLedger Authenticated');
            launchValueSelection('sl');
          } else {
            error('Invalid Credentials. Please try again or contact support')
          }
        })
        .catch((e: any) => console.error(e));
    }
  }

  /** Setting the integration source to updated value,
   * so the user can view the updated value when they
   * open the modal next
   *
   * The possible values of integrationSelected are 3,4,5 only
   * */
  const confirmValueSelection = (p: any) => {
    const { state } = p;

    let updatedValue = {};
    if (integrationSelected == INTEGRATION_SOURCE_TYPE.INTACCT) { //Intacct
      // do the mutation to update the source.
      updatedValue = {
        taskId: integrationTask.id,
        sourceType: integrationSource,
        value: Number(state.balance),
        valueSource: INTEGRATION_SOURCE_TYPE.INTACCT,
        integrationInfo: {
          inEntities: idx(state, (s: any) => s.inEntities),
          inDepartments: idx(state, (s: any) => s.inDepartments),
          inUnderliers: idx(state, (s: any) => s.inUnderliers),
          inAccounts: idx(state, (s: any) => s.inAccounts),
          inServices: idx(state, (s: any) => s.inServices),
          inCustomers: idx(state, (s: any) => s.inCustomers),
          inVendors: idx(state, (s: any) => s.inVendors),
          inAccountingMethod: idx(state, (s: any) => s.inAccountingMethod),
          inStartDate: state.inStartDate,
          inEndDate: state.inEndDate,
          startDateLocked: state.startDateLocked,
          endDateLocked: state.endDateLocked,
          invert: state.invert,
          includeReportingBook: state.includeReportingBook,
          currency: state.currency,
        }
      };
      updateTaskRecon(updatedValue);
      updateTaskZeroBalance({
        variables: {
          taskId: integrationTask.id
        }
      }).then((response: any) => {
        const updateTaskZeroBalanceData = response?.data?.updateTaskZeroBalance;
        // Errors are currently on the backend side.
        // TODO: Find a way to better handle the errors on the frontend side.
        if (updateTaskZeroBalanceData?.success) {} else {}
      })
    } else if (integrationSelected == INTEGRATION_SOURCE_TYPE.SOFTLEDGER) { //SoftLedger
      // do the mutation to update the source.
      //endDate={idx(listData, (l: any) => l.reconEndDate)}
      if (p['Transaction Type (optional)'] === null) { p['Transaction Type (optional)'] = ''; }
      if (p['Period Adjustment (optional)'] === null) { p['Period Adjustment (optional)'] = ''; }
      updatedValue = {
        taskId: integrationTask.id,
        sourceType: integrationSource,
        value: Number(p.balance),
        valueSource: INTEGRATION_SOURCE_TYPE.SOFTLEDGER,
        integrationInfo: {
          companyId: p.Location.toString(),
          locationId: '',
          ledgerId: p['GL Account'].toString(),
          transactionType: p['Transaction Type (optional)'].toString(),
          period: p['Period Adjustment (optional)'].toString(),
          slStartDate: p.startDate,
          slEndDate: p.endDate
        }
      }
      updateTaskRecon(updatedValue)
    }
    else if (integrationSelected == INTEGRATION_SOURCE_TYPE.QBO) {
      updatedValue = {
        taskId: integrationTask.id,
        sourceType: integrationSource,
        value: Number(state.balance),
        valueSource: INTEGRATION_SOURCE_TYPE.QBO,
        integrationInfo: {
          qboIntegration: idx(state, (s: any) => s.qboIntegration),
          qboAccounts: idx(state, (s: any) => s.accounts),
          qboNames: idx(state, (s: any) => s.names),
          qboClasses: idx(state, (s: any) => s.classes),
          qboLocations: idx(state, (s: any) => s.locations),
          qboTypes: idx(state, (s: any) => s.types),
          qboAccountingMethod: state.accountingMethod,
          qboStartDate: state.startDate,
          qboEndDate: state.endDate,
          startDateLocked: state.startDateLocked,
          endDateLocked: state.endDateLocked,
        }
      }
      updateTaskRecon(updatedValue)
    } else if (integrationSelected == INTEGRATION_SOURCE_TYPE.NETSUITE) {
      updatedValue = {
        taskId: integrationTask.id,
        sourceType: integrationSource,
        value: Number(state.balance),
        valueSource: INTEGRATION_SOURCE_TYPE.NETSUITE,
        integrationInfo: {
          nsSubsidiaries: idx(state, (s: any) => s.nsSubsidiaries),
          nsDepartments: idx(state, (s: any) => s.nsDepartments),
          nsClasses: idx(state, (s: any) => s.nsClasses),
          nsAccounts: idx(state, (s: any) => s.nsAccounts),
          nsItems: idx(state, (s: any) => s.nsItems),
          nsEntities: idx(state, (s: any) => s.nsEntities),
          nsBooks: idx(state, (s: any) => s.nsBooks),
          nsStartDate: state?.nsStartDate?.value,
          nsEndDate: state?.nsEndDate?.value,
          startDateLocked: state.startDateLocked,
          endDateLocked: state.endDateLocked,
          invert: state.invert,
          currency: state.currency,
        }
      }
      updateTaskRecon(updatedValue)
    }

    function getValue(object: any) {
      return object.value;
    }

    const newListData = Object.assign({}, listData);
    const newTask = newListData.tasks.find((t: any) => t.id === integrationTask.id);
    newTask[`${integrationSource}SourceType`] = integrationSelected;
    if (updatedValue) {
      newTask[`${integrationSource}Source`] = updatedValue;
    }
    setListData(newListData);
    closeModals();
  }

  /** 2 ways of representing enabled recons... TODO: change this */
  const checked = tasks.every((t: any) =>
    ['1', true].includes(t.reconciliations)
  );

  const handleAllStatusClick = (e: any) => {
    updateAllTaskRecons(!checked);
    bulkTaskReconAssign(listData.id, !checked)
  }

  const updateAllTaskRecons = (reconEnabled: boolean) => {
    let currentSelectedTaskIds = listData.tasks
      .filter((task: any) => sectionFilter.length
        ? sectionFilter.includes(idx(task, t => t.section.name))
        : true
      ).filter((task: any) => {
        return true
      }).map((task: any) => task.id);

    setListData({
      ...listData, tasks: listData.tasks
        .map((t: any) => {
          if (currentSelectedTaskIds.includes(t.id)) {
            return (({ ...t, reconciliations: reconEnabled }))
          } else {
            return (t)
          }
        })
    })
  }

  const applySorting = sortingFunctionHelper(activeSort, nameSort, dueDateSort, differenceSort, primarySort, secondarySort);

  return (
    <>
      {!props.isCreate && !props.isNewProjectCreate &&
        <Header
          onClose={navigateToLastMainPage}
          onClickBack={navigateToLastMainPage}
        />
      }
      <IntegrationValueSelection
        open={showValueSelection}
        denyAction={closeModals}
        integrationType={integrationSelected}
        sourceType={integrationSource}
        task={integrationTask}
        project={listData}
        confirmAction={confirmValueSelection}
      />

      <IntegrationEnable
        open={showEnable}
        denyAction={closeModals}
        integrationType={integrationSelected}
        confirmAction={launchAuthenticate}
      />

      <IntegrationAuthentication
        open={showAuthentication}
        denyAction={closeModals}
        integrationType={integrationSelected}
        confirmAction={authenticateUser}
        loading={authenticateUserLoading}
      />

      <div style={{ display: 'flex', flexDirection: 'column', height: '100%', padding: '0px 50px 0px 50px' }}>
        <div style={{ display: 'flex', alignItems: 'center', marginBottom: 40 }}>
          <Typography className={classes.ellipsis} style={{ maxWidth: 430 }} variant='h2'>
            {`${idx(listData, (l: any) => l.name)}`}
          </Typography>
          <Typography variant='h2'>{'\u00A0\u00A0\u00A0\u00A0|\u00A0\u00A0\u00A0\u00A0'}</Typography>
          <Typography style={{ marginRight: 20 }} variant='h6'>Reconciliation Settings:</Typography>
          <Dropdown
            handleUpdate={updateProjectReconType}
            options={RECON_TYPES}
            selected={idx(listData, (l: any) => l.reconciliations)}
            small
            noFlow
            zindex
          />
          <Typography style={{ marginLeft: 30, marginRight: 20 }} variant='h6'>Reconciliation period end date:</Typography>
          <DatePicker
            disabled={!currentUserIsOwner}
            value={idx(listData, (l: any) => l.reconEndDate)}
            onChange={updateProjectReconDate}
            classes={clsx(!currentUserIsOwner && classes.disabled)}
          />
          {!(props.isCreate || props.isNewProjectCreate) &&
            <Button
              onClick={navigateToReconSummary}
              style={{ marginLeft: 30 }}
            >
              Go To Reconciliation Summary
            </Button>
          }
        </div>
        {reconSettings.loading
          ? <div style={{ textAlign: 'center' }}>
            <CircularLoader height={'50vh'} />
          </div>
          :
          <Table stickyHeader>
            <TableHead>
              <TableRow>
                <HeaderCell
                  onClick={updateNameSort}
                  label='Name'
                  isActive={activeSort === 'name'}
                  className={classes.leftAlign}
                />

                <TableCell
                  className={classes.showIconOnHover}
                  onClick={() => setShowSectionFilter(true)}
                >
                  <div style={{ display: 'flex', alignItems: 'center', height: 48 }}>
                    <span>Section</span>
                    <ArrowDropDown
                      style={{
                        visibility: sectionFilter.length ? 'visible' : undefined,
                        color: sectionFilter.length ? COLORS.primary : 'black'
                      }}
                    />
                    <div style={{ position: 'relative' }}>
                      {showSectionFilter &&
                        <ClickAwayListener onClickAway={() => setShowSectionFilter(false)}>
                          <div className={classes.ellipsis} style={{ maxWidth: 260, position: 'absolute', left: -206, top: 24, zIndex: 10 }}>
                            <SectionFilterBox
                              options={
                                sections.map((s: any, i: number) =>
                                  <Typography className={classes.ellipsis} key={i}>
                                    {s}</Typography>
                                )
                              }
                              handleClick={handleSectionFilterClick}
                              handleClickAll={() => setSectionFilter([])}
                              selected={sectionFilter}
                            />
                          </div>
                        </ClickAwayListener>
                      }
                    </div>
                  </div>
                </TableCell>

                <TableCell>
                  <div style={{ display: 'flex', alignItems: 'center', marginLeft: -11, height: 48 }}>
                    <Checkbox
                      disabled={!currentUserIsOwner}
                      checked={checked}
                      color='primary'
                      onClick={handleAllStatusClick}
                    />
                    <span>Display Status</span>
                  </div>
                </TableCell>


                <TableCell style={{ minWidth: 170, paddingBottom: 12 }} onClick={updatePrimarySort}>
                  <div style={{ display: 'flex', flexDirection: 'column', whiteSpace: 'nowrap' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', whiteSpace: 'nowrap' }}>
                      <Typography style={{ fontWeight: 'bold', marginBottom: 10 }}>Primary Source Name</Typography>
                    </div>
                    <div style={{ position: 'relative' }}>
                      <InputForm
                        disableEdit={!currentUserIsOwner}
                        name='primaryLabel'
                        className={clsx(classes.capital, classes.ellipsis, classes.w180)}
                        value={primaryListLabel}
                        onChange={(e) => setPrimaryListLabel(e.target.value)}
                        onBlur={(e) => handleListLabel(e)}
                        placeholder='Edit label for project'
                      />
                    </div>
                  </div>
                </TableCell>

                <TableCell>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 48 }}>
                    <span>Input Method</span>
                  </div>
                </TableCell>

                <TableCell style={{ minWidth: 185, paddingBottom: 12 }}>
                  <div style={{ display: 'flex', flexDirection: 'column' }}>
                    <Typography style={{ fontWeight: 'bold', marginBottom: 10 }}>Secondary Source Name</Typography>
                    <InputForm
                      disableEdit={!currentUserIsOwner}
                      name='secondaryLabel'
                      className={clsx(classes.capital, classes.ellipsis, classes.w180)}
                      value={secondaryListLabel}
                      onChange={(e) => setSecondaryListLabel(e.target.value)}
                      onBlur={handleListLabel}
                      placeholder='Edit label for project'
                    />
                  </div>
                </TableCell>

                <TableCell>
                  <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: 48 }}>
                    <span>Input Method</span>
                  </div>
                </TableCell>


              </TableRow>
            </TableHead>
            <TableBody>
              {tasks.sort((task1: any, task2: any) => Number(task1.listNumber) - Number(task2.listNumber))
                .filter((task: any) => sectionFilter.length
                  ? sectionFilter.includes(idx(task, t => t.section.name))
                  : true
                ).filter((task: any) => {
                  return true
                }).sort(applySorting)
                .map((task: any, index: number) =>
                  <ReconSettingsRow
                    onToggle={handleTaskToggle}
                    listReconType={listReconType}
                    handleValueSourceChange={handleValueSourceChange}
                    handleAliasChange={handleAliasChange}
                    task={task}
                    key={index}
                    currentUserIsOwner={currentUserIsOwner}
                    state={state}
                  />
                )}
              {tasks.length === 0 && <Typography style={{ marginTop: 20 }}>You have no active reconciliations</Typography>}
            </TableBody>
          </Table>
        }
      </div>
    </>
  );
}

function ReconSettingsRow(props: any) {
  const { task, state: { currentUser: { currentCompany } } } = props;

  const active = Boolean(Number(task.reconciliations));
  const notActive = props.listReconType === 'Not Active' || !props.currentUserIsOwner;
  const classes = useStyles();

  const [primaryValue, setPrimaryValue] = useState<string>(task.primaryLabel);
  const [secondaryValue, setSecondaryValue] = useState<string>(task.secondaryLabel);

  const tableCellStyling = task.tags.length ? classes.reconTableCell : '';
  const paddingTopStyling = task.tags.length ? '9px' : '0px';
  const inputFormPaddingStyling = task.tags.length ? classes.reconSettingsInputForm : '';
  const dropDownStyling = task.tags.length ? classes.reconSettingsDropdownStyle : '';

  // Check if the current company is in the list of companies that can lock Intacct or QBO.
  const canLockIntacct = LOCKED_INTACCT_SOURCE_COMPANIES.includes(currentCompany.name);
  const canLockQBO = LOCKED_QBO_SOURCE_COMPANIES.includes(currentCompany.name);
  const canLockNetsuite = LOCKED_NETSUITE_SOURCE_COMPANIES.includes(currentCompany.name);

  // Checks if task reconciliation is enabled based on the task's reconciliation value.
  const isTaskReconEnabled = task.reconciliations === '1';

  // Check if Intacct or QBO or Netsuite can be locked as a primary source.
  const lockedIntacctPrimarySource = canLockIntacct && isTaskReconEnabled;
  const lockedQBOPrimarySource = canLockQBO && isTaskReconEnabled;
  const lockedNetsuitePrimarySource = canLockNetsuite && isTaskReconEnabled;

  // Based on whether Intacct can be locked, filter the available primary value source types.
  const primaryValueSourceTypes = VALUE_SOURCE_TYPES
    .filter((v) => lockedIntacctPrimarySource ? Number(v.id) === INTACCT_SOURCE_TYPE_ID : true)
    .filter((v) => lockedQBOPrimarySource ? Number(v.id) === QBO_SOURCE_TYPE_ID : true)
    .filter((v) => lockedNetsuitePrimarySource ? Number(v.id) === NETSUITE_SOURCE_TYPE_ID : true);

  // Set selected primary source type to Intacct for the authorised companies only.
  const selectedPrimarySourceType = primaryValueSourceTypes.length === 1
    ? primaryValueSourceTypes[0].id : task.primarySourceType;

  // Set secondary value source types (no filtering applied).
  const secondaryValueSourceTypes = VALUE_SOURCE_TYPES;

  // Set selected secondary source type.
  const selectedSecondarySourceType = task.secondarySourceType;

  return (
    <TableRow>
      <TableCell className={tableCellStyling} style={{ maxWidth: 400, minWidth: 260 }}>
        <Tooltip title={task.name} aria-label="task-name" classes={{ tooltip: classes.tooltipFont }}>
          <Typography className={classes.ellipsis} style={{ maxWidth: 400, minWidth: 260, paddingTop: paddingTopStyling }}>
            {task.name}
          </Typography>
        </Tooltip>
        <div style={{ display: 'flex', flexDirection: 'row', flexWrap: 'wrap', maxWidth: 400, marginBottom: '6px' }}>
          <EntityBadges entities={task.tags} trimmed={false} containerStyles={{ margin: '2px 2px 2px 0px' }} />
        </div>
      </TableCell>
      <TableCell className={tableCellStyling} style={{ maxWidth: 250, minWidth: 200 }}>
        <Typography className={classes.ellipsis} style={{ maxWidth: 250, minWidth: 200, paddingTop: paddingTopStyling }}>
          {idx(task, t => t.section.name) || 'No Section'}
        </Typography>
      </TableCell>
      <TableCell style={{ minWidth: 180 }} className={tableCellStyling}>
        <FormControlLabel
          control={
            <Checkbox
              disabled={notActive}
              checked={active}
              color='primary'
              onClick={() => props.onToggle(task)}
              size='small'
            />
          }
          label={
            (notActive || !active) ? 'Not Active' : props.listReconType
          }
        />
      </TableCell>
      <TableCell className={tableCellStyling}>
        <InputForm
          disableEdit={!props.currentUserIsOwner}
          name='primary'
          className={clsx(classes.capital, classes.ellipsis, classes.w180, inputFormPaddingStyling)}
          value={primaryValue}
          onChange={(e: any) => setPrimaryValue(e.target.value)}
          onKeyUp={(keyCode: string) => props.handleAliasChange(task, primaryValue, 'primary', keyCode)}
          placeholder='Primary source name'
        />
      </TableCell>
      <TableCell className={tableCellStyling}>
        <Dropdown
          disabled={!props.currentUserIsOwner}
          placeholder='primary input method'
          handleUpdate={(u: any) => props.handleValueSourceChange(task, u, 'primary')}
          options={primaryValueSourceTypes}
          selected={String(selectedPrimarySourceType)}
          small
          noFlow
          ellipsis
          goneSoon
          classes={dropDownStyling}
        />
      </TableCell>
      <TableCell className={tableCellStyling}>
        <InputForm
          disableEdit={!props.currentUserIsOwner}
          name='secondary'
          className={clsx(classes.capital, classes.ellipsis, classes.w180, inputFormPaddingStyling)}
          value={secondaryValue}
          onChange={(e: any) => setSecondaryValue(e.target.value)}
          onKeyUp={(keyCode: string) => props.handleAliasChange(task, secondaryValue, 'secondary', keyCode)}
          placeholder='Secondary source name'
        />
      </TableCell>
      <TableCell className={tableCellStyling}>
        <Dropdown
          disabled={!props.currentUserIsOwner}
          placeholder='secondary input method'
          handleUpdate={(u: any) => props.handleValueSourceChange(task, u, 'secondary')}
          options={secondaryValueSourceTypes}
          selected={String(selectedSecondarySourceType)}
          small
          noFlow
          ellipsis
          goneSoon
          classes={dropDownStyling}
        />
      </TableCell>
    </TableRow>
  );
}

function StatusFilterBox(props: any) {
  return (
    <Paper style={{ boxShadow: 'none', display: 'flex', flexDirection: 'column', border: '1px solid #CACACA' }}>
      <List>
        <ListItem style={{ display: 'flex', justifyContent: 'space-between' }} onClick={() => props.handleClick()} tabIndex={0}>
          Select all
          <Check style={{ marginLeft: 8, visibility: props.selected ? 'inherit' : 'hidden' }} />
        </ListItem>
      </List>
    </Paper>
  )
}

function SectionFilterBox(props: any) {
  const classes = useStyles();
  return (
    <Paper style={{ boxShadow: 'none', display: 'flex', flexDirection: 'column', border: '1px solid #CACACA' }}>
      <List>
        <ListItem>
          <Typography variant='h6' onClick={props.handleClickAll} style={{ whiteSpace: 'nowrap', wordBreak: 'keep-all' }}><span style={{ whiteSpace: 'nowrap', wordBreak: 'keep-all' }}>Select All</span></Typography>
        </ListItem>
        {props.options.map((o: any, i: number) =>
          <ListItem key={i} style={{ outline: 'none', display: 'flex', justifyContent: 'space-between' }} onClick={() => props.handleClick(o)} tabIndex={0}>
            {o}
            <Check style={{ color: COLORS.primary, marginLeft: 8, visibility: props.selected.includes(o.props.children) ? 'inherit' : 'hidden' }} />
          </ListItem>
        )}
      </List>
    </Paper>
  )
}

interface HeaderCellProps {
  isActive: boolean;
  label: string;
  className?: string;
  onClick?: any
}

function HeaderCell(props: HeaderCellProps) {
  const classes = useStyles();
  return (
    <TableCell
      className={clsx(classes.showIconOnHover, props.className)}
      onClick={props.onClick}
    >
      <div
        className={classes.flexCenter}
        style={{ height: 48 }}
      >
        <span style={{ whiteSpace: 'nowrap' }}>{props.label}</span>
        <ArrowDropDown
          style={{
            visibility: props.isActive ? 'visible' : undefined,
            color: props.isActive ? COLORS.primary : 'black'
          }}
        />
      </div>
    </TableCell>);
}
